import React from 'react';
import { animated, useTransition } from 'react-spring';
import { ModalOverlay } from 'ds/components/modal/styled';
import { WizardMainWrapper } from './styled';
import { useWindowSize } from 'hooks/useWindowSize';
import { ShowAt } from 'consts/breakpoints';
import { OverlayWrapper } from 'components/overlay-wrapper';
import { connect } from 'react-redux';
import { State } from 'store/state';
import { homepageWizardOpenSelector } from 'store/state/homepageWizard/selectors';
import { setWizardOpen as setWizardOpenAction } from 'store/state/homepageWizard/actions';

interface AnimatedWizardProps {
  isOpen: boolean;
  desktopWidth: number;
  setWizardOpen: (isOpen: boolean) => void;
}

const AnimatedWizardWrapper = animated(WizardMainWrapper);

const AnimatedWizard: React.FC<AnimatedWizardProps> = ({ isOpen, setWizardOpen, children, desktopWidth }) => {
  const [ , windowHeight ] = useWindowSize();
  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  const onClose = () => setWizardOpen(false);

  return (
    <>
      <ShowAt from={3}>
        {isOpen ? <ModalOverlay onClick={onClose} /> : null}
        {transitions.map(({ item, key, props }) => item ? (
          <OverlayWrapper key={key}>
            <AnimatedWizardWrapper
              desktopWidth={desktopWidth}
              style={{
                opacity: props.opacity.to((v) => v),
                transform: props.opacity.to((v: number) => (
                  `translate3d(-${desktopWidth * (1 - v)}px, 0, 0)`
                )),
                minHeight: windowHeight,
              }}
              data-auto="wizard-block"
            >
              {children}
            </AnimatedWizardWrapper>
          </OverlayWrapper>
        ) : null)}
      </ShowAt>
    </>
  );
};

const mapStateToProps = (state: State) => ({
  isOpen: homepageWizardOpenSelector(state),
});

const mapDispatchToProps = {
  setWizardOpen: (isOpen: boolean) => setWizardOpenAction({ isOpen }),
};

export default connect(mapStateToProps, mapDispatchToProps)(AnimatedWizard);
