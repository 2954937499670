import React from 'react';
import { StepsProgress } from 'components/progress';
import { WizardNavigationButtons } from './WizardNavigationButtons';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { BaseStepHeader, StepsProgressWrapper } from './styled';
import { colorToAlpha } from 'helpers/insight';
import { useTheme } from 'hooks/useTheme';
import { HomepageWizardStep } from 'store/state/homepageWizard';


interface StepNavigatorProps {
  title: string;
  currentStepIndex: number;
  steps: HomepageWizardStep[];
}

export const StepNavigator: React.FC<StepNavigatorProps> = (props) => {
  const isMobile = useScreenBreakpoint() < 3;
  const { colors } = useTheme();

  const {
    title,
    currentStepIndex,
    steps,
    children,
  } = props;

  const stepProgressOptions = {
    count: steps.length,
    current: currentStepIndex,
    withoutGaps: true,
    withStepInfo: true,
    height: isMobile ? 4 : 6,
    background: isMobile ? null : colorToAlpha(colors.brand.main, 0.12),
  };

  return (
    <>
      <StepsProgressWrapper>
        <StepsProgress {...stepProgressOptions} />
      </StepsProgressWrapper>
      {title ? <BaseStepHeader weight="bold">{title}</BaseStepHeader> : null}
      {children}
      <WizardNavigationButtons
        currentStep={steps[currentStepIndex]}
        isMobile={isMobile}
      />
    </>
  );
};
