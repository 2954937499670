import React from 'react';
import styled from '@emotion/styled';
import { Text } from 'ds/components/typography';
import defaultTheme from 'ds/theme/default';

type Size = 'large' | 'extraLarge' | 'medium';
type State = 'default' | 'hovered';
type Mode = 'primary' | 'selected' | 'error' | 'fillTile';

interface RadioChipProps {
  fullWidth?: boolean;
  mode?: Mode;
  label?: string;
  size?: Size;
  onClick?: () => void;
}

const modeToFontColor: Record<State, Record<Mode, string>> = {
  default: {
    primary: defaultTheme.colors.neutrals.grey1,
    selected: defaultTheme.colors.brand.main,
    error: defaultTheme.colors.neutrals.grey1,
    fillTile: defaultTheme.colors.neutrals.white,
  },
  hovered: {
    primary: defaultTheme.colors.neutrals.grey1,
    selected: defaultTheme.colors.brand.main,
    error: defaultTheme.colors.neutrals.grey1,
    fillTile: defaultTheme.colors.neutrals.white,
  },
};

const modeToBorderColor: Record<State, Record<Mode, string>> = {
  default: {
    primary: defaultTheme.colors.neutrals.grey6,
    selected: defaultTheme.colors.brand.lighter,
    error: defaultTheme.colors.alert.red,
    fillTile: defaultTheme.colors.brand.main,
  },
  hovered: {
    primary: defaultTheme.colors.neutrals.grey4,
    selected: defaultTheme.colors.brand.dark,
    error: defaultTheme.colors.alert.red,
    fillTile: defaultTheme.colors.brand.main,
  },
};

const modeToBgColor: Record<State, Record<Mode, string>> = {
  default: {
    primary: defaultTheme.colors.neutrals.white,
    selected: defaultTheme.colors.brand.lightest,
    error: defaultTheme.colors.alert.redBackground,
    fillTile: defaultTheme.colors.brand.main,
  },
  hovered: {
    primary: defaultTheme.colors.neutrals.white,
    selected: defaultTheme.colors.brand.lightest,
    error: defaultTheme.colors.alert.redBackground,
    fillTile: defaultTheme.colors.brand.main,
  },
};

const sizeToPadding: Record<Size, string> = {
  extraLarge: `${defaultTheme.spacing(2)} ${defaultTheme.spacing(2)}`,
  large: `${defaultTheme.spacing(1.5)} ${defaultTheme.spacing(2)}`,
  medium: `${defaultTheme.spacing(1)} ${defaultTheme.spacing(2)}`,
};

const Wrapper = styled.div<RadioChipProps>`
  cursor: pointer;
  display: inline-block;
  text-align: center;

  ${({ fullWidth, theme, mode = 'primary', size = 'large' }) => `
    border-radius: ${theme.borderRadius.extraXLarge};
    padding: ${sizeToPadding[size]};

    color: ${modeToFontColor.default[mode]};
    border: 1px solid ${modeToBorderColor.default[mode]};
    background-color: ${modeToBgColor.default[mode]};

    &:hover {
      color: ${modeToFontColor.hovered[mode]};
      border: 1px solid ${modeToBorderColor.hovered[mode]};
      background-color: ${modeToBgColor.hovered[mode]};
    }
    ${fullWidth ? 'width: 100%;' : ''}
  `}
`;

const RadioChip: React.FC<RadioChipProps> = (props) => {
  const { label, onClick, ...rest } = props;

  return (
    <Wrapper {...rest} onClick={onClick}>
      <input type="checkbox" hidden onChange={onClick} checked={rest.mode === 'selected'}/>
      <Text weight={rest.mode === 'fillTile' ? 'bold' : 'normal'}>{label}</Text>
    </Wrapper>
  );
};

export default RadioChip;
