import { HPSlide, LoadType, SlideType } from 'store/sagas/apiService/types';
import { homePageBannerDomainSelector, makeNullSafeDomainGetter, createIsLoadingSelector } from './common';
import { createSelector } from 'reselect';
import { State } from 'store/state';
import { ITranslate } from 'locale';
import config from 'config';
import { BreakpointValue } from 'consts/breakpoints';


const homePageBannerSelector = makeNullSafeDomainGetter(homePageBannerDomainSelector, LoadType.HomePageBanner);
const isHomePageBannerLoadingSelector = createIsLoadingSelector(homePageBannerDomainSelector);

function defaultSlideFactory(type: SlideType, t: ITranslate): HPSlide {
  const isDesktop = type === SlideType.Desktop;
  return {
    id: Date.now().toString(),
    slideType: type,
    status: 'publish',
    title: t('homePage.welcome.title'),
    subtitle: t('homePage.welcome.subTitle'),
    navigation: {
      text: t('homePage.welcome.subTitleLink'),
      link: '/madad',
    },
    aside: {
      link: '/madad',
      file: {
        url: `${config.imagesRoot}madad/badge-2023-${isDesktop ? 'desktop' : 'mobile'}.png`,
        title: 'madad-badge',
      },
    },
    backgroundFile: {
      url: `${config.imagesRoot}homepage/v3/${isDesktop ? 'cover-new.gif' : 'Background.png'}`,
      title: 'cover',
    },
  };
}

function filterPublish(sl: HPSlide[]) {
  return sl.filter(({ status }) => status === 'publish');
}
export const safeHPBannerSelector = createSelector([
  homePageBannerSelector,
  isHomePageBannerLoadingSelector,
  (_: State, t: ITranslate, screenBreakpoint: BreakpointValue) => ({ t, screenBreakpoint }),
], (banner, isLoading, { t, screenBreakpoint }): HPSlide[] => {
  const defaultMobile = defaultSlideFactory(SlideType.Mobile, t);
  const defaultDesktop = defaultSlideFactory(SlideType.Desktop, t);
  const isMobile = screenBreakpoint <= 2;

  if (isLoading) return null;
  if (banner) {
    const { desktop, mobile } = banner;
    if (isMobile) {
      const filteredMobile = (mobile && filterPublish(mobile)) || [];
      return filteredMobile.length ? filteredMobile : [ defaultMobile ];
    }
    else {
      const filteredDesktop = (desktop && filterPublish(desktop)) || [];
      return filteredDesktop.length ? filteredDesktop : [ defaultDesktop ];
    }
  }
  return [ isMobile ? defaultMobile : defaultDesktop ];
});
