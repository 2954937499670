import React from 'react';
import { connect } from 'react-redux';
import {
  BaseStepSubHeader,
  PurchasingReasonContainer,
  PurchasingReasonButton,
} from './styled';
import { homePageWizardPurchasingReasonSelector } from 'store/state/homepageWizard/selectors';
import { setPurchasingReasonAction } from 'store/state/homepageWizard/actions';
import { State } from 'store/state';
import { useLocale } from 'locale';
import { purchasingReasonOptions } from '../config';
import { PurchasingReason } from 'utils/entities';


interface PurchasingReasonStepProps {
  onPurchasingReason: (reason: PurchasingReason) => void;
  purchasingReason: PurchasingReason;
}

const PurchasingReasonStep: React.FC<PurchasingReasonStepProps> = (props) => {
  const { purchasingReason, onPurchasingReason } = props;
  const { t } = useLocale();
  const handlePurchasingReasonSelection = (item: PurchasingReason) => onPurchasingReason(item);

  return (
    <>
      <BaseStepSubHeader>{t('homePage.wizard.purchasingReason.subtitle' )}</BaseStepSubHeader>
      <PurchasingReasonContainer>
        {purchasingReasonOptions.map((item) => (
          <div key={item}>
            <PurchasingReasonButton
              size="large"
              selected={purchasingReason === item}
              onClick={() => handlePurchasingReasonSelection(item)}
            >
              {t('homePage.wizard.purchasingReason.list', { reason: item })}
            </PurchasingReasonButton>
          </div>
        ))}
      </PurchasingReasonContainer>
    </>
  );
};

const mapStateToProps = (state: State) => ({
  purchasingReason: homePageWizardPurchasingReasonSelector(state),
});

const mapDispatchToProps = {
  onPurchasingReason: setPurchasingReasonAction,
};

export const ConnectedPurchasingReasonStep = connect(mapStateToProps, mapDispatchToProps)(PurchasingReasonStep);
