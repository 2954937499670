import { PoiId } from 'utils/entities';
import { DecodedRouteParams } from 'config/routes';

type LinkRouteParamsFn = (id: PoiId, params: DecodedRouteParams, eventSource?: string) => DecodedRouteParams;

export const getLinkRouteParams: LinkRouteParamsFn = (id, params, eventSource) => {
  const res = { ...params, id };

  if (eventSource) {
    res.tracking_event_source = eventSource;
  }
  return res;
};
