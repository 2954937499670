import React from 'react';
import { useLocale } from 'locale';
import { Wrapper } from './styled';
import { H2 } from 'ds/components/typography';
import Carousel from 'ds/components/carousels/carousel';
import { Link } from 'react-router5';
import { ConnectedUniversalCard } from '../BulletinsSection';
import { DealType, IBulletin } from 'utils/entities';
import { DecodedRouteParams, Route, State as RouteState } from 'config/routes';
import config from 'config';
import { Seller } from 'components/filters/types';
import { ShowAt, useScreenBreakpoint } from 'consts/breakpoints';
import { ItemWrapper } from 'ds/components/carousels/carousel/styled';
import { getLinkRouteParams } from 'ds/components/carousels/carousel/utils';
import { HomepageButton } from '../HomepageButton';
import { routeByPoiType } from 'utils/marketplaceRoutes';

export interface ProjectsProps {
  route: RouteState;
  slides: IBulletin[];
}

const Projects: React.FC<ProjectsProps> = ({ route, slides }) => {
  const { t } = useLocale();
  const designRange = useScreenBreakpoint();
  const isMobile = designRange === 1;
  const params: DecodedRouteParams = { ...route.params, tracking_search_source: 'homepage_projects' };

  return (
    <Wrapper>
      <H2 data-auto="new-projects" weight="bold">{t('homePage.projects.newProjects')}</H2>
      <ShowAt from={3}>
        {(hasArrows =>
          <Carousel hasArrows={hasArrows} isLoading={!slides.length}>
            {slides.map(({ id, type }) => (
              <ItemWrapper key={id}>
                <Link
                  routeName={routeByPoiType(type)}
                  routeParams={getLinkRouteParams(id, params)}
                >
                  <ConnectedUniversalCard
                    id={id}
                    onlyOneImageShouldBeDisplayed={isMobile}
                    emptyLazyLoadPlaceholder
                  />
                </Link>
              </ItemWrapper>
            ))}
          </Carousel>
        )}
      </ShowAt>
      <Link
        data-auto="new-projects-link"
        routeName={Route.Search}
        routeParams={{
          term: [ config.cityTerm ],
          dealType: DealType.Buy,
          filters: {
            seller: [ Seller.Developer ],
          },
          tracking_search_source: 'homepage_projects',
        }}
      >
        <HomepageButton>
          {t('homePage.projects.allProjects')}
        </HomepageButton>
      </Link>
    </Wrapper>
  );
};

export default Projects;
