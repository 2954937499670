import styled from '@emotion/styled';
import { Text, H1 } from 'ds/components/typography';

export const DESKTOP_WIDTH = 560;

export const ButtonsContainer = styled.div<{ hideBackAction?: boolean }>`
  ${({ theme, hideBackAction }) => `
    position: absolute;
    bottom: 48px;
    left: 48px;
    right: 48px;
    display: flex;
    align-items: center;
    background: ${theme.colors.neutrals.white};
    justify-content: ${hideBackAction ? 'flex-end' : 'space-between'};
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    position: fixed;
    z-index: 1;
    width: auto;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px 16px;
    box-shadow: ${theme.shadow.level7};
  `}
`;

export const WizardMainWrapper = styled.div<{ desktopWidth: number }>`
  ${({ theme, desktopWidth }) => `
    width: ${desktopWidth}px;
    background: ${theme.colors.neutrals.white};
    box-shadow: ${theme.shadow.level7};
    color: ${theme.colors.neutrals.grey1};
    ${theme.isRTL ? 'left: 0' : 'right: 0'};
    z-index: 1000;
    position: relative;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    position: fixed;
  `}
`;

export const StepsProgressWrapper = styled.div`
 > div {
    display: flex;
    flex-direction: column-reverse;
  }

 .wizard-steps-line {
    margin: 0 ${({ theme }) => `-${theme.spacing(6)} ${theme.spacing(6)}`};
  }

  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin-top: -${theme.spacing(7)};

    > div {
      flex-direction: column;
    }

     .wizard-steps-line {
        margin: 0 ${`-${theme.spacing(3)} ${theme.spacing(4)}`};
      }
  `};
`;

export const WizardWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.spacing(6)};
`;

export const MobileWizardWrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.media.showAt({ to: 2 })`
    ${theme.isRTL ? '' : `
      .textfield-input {
        color: ${theme.colors.neutrals.grey3};
        min-height: ${theme.spacing(4)};
        background: ${theme.colors.neutrals.grey10};
        border-color: ${theme.colors.neutrals.grey9};
      }
    `}
  `};
`;

export const BaseStepHeader = styled(H1)<{ single?: boolean }>`
  ${({ theme, single }) => `
    color: ${theme.colors.neutrals.grey1};
    margin-bottom: ${single ? theme.spacing(7) : theme.spacing(1)};
  `}
  ${({ theme, single }) => theme.media.showAt({ to: 2 })`
    margin-bottom: ${single ? theme.spacing(5) : theme.spacing(1)};
  `}
`;

export const CategoryLabelWizard = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
    margin-bottom: ${theme.spacing(2)};
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 }) `
    ${theme.isRTL ? `margin-bottom: ${theme.spacing(1)}` : ''};
  `}
`;

export const CategoryLabelWizardText = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey3};
    margin-bottom: ${theme.spacing(2)};
  `};
`;

export const WizardContentInner = styled.div`
  ${({ theme }) => theme.media.showAt({ from: 3 }) `
    ${theme.isRTL ? '' : `margin-top: ${theme.spacing(7)};`}
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    padding-top: ${theme.isRTL ? theme.spacing(2) : theme.spacing(1)};
  `};
`;

export const WizardContentWrapper = styled.div`
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    height: 100%;
  `}
`;

export const CategoryWrapper = styled.div<{ big?: boolean }>`
  margin-bottom: ${({ big, theme }) => big ? theme.spacing(7) : theme.spacing(5)};
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin-bottom: ${theme.spacing(theme.isRTL ? 4 : 5)};
  `}
`;
