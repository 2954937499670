import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'hooks/useTheme';
import { GenericCommutePreferenceForm, PreferredAddress } from 'components/commute-popup/GenericCommutePreferenceForm';
import {
  setCommuteAddress as setCommuteAddressAction,
  setCommuteSubStep as setCommuteSubStepAction,
} from 'store/state/homepageWizard/actions';
import { useLocale } from 'locale';
import LocationIcon from 'assets/svg/location-gallery.svg';
import { homepageWizardSelector } from 'store/state/homepageWizard/selectors';
import { HomepageWizardState } from 'store/state/homepageWizard';
import { CommuteSoonBus } from 'components/commute-popup/components/CommuteSoonBus';
import { WizardCommuteTrain } from '../connectedWizardSubSteps';


export type CommutePreferenceSelectPropsBase = Pick<HomepageWizardState, 'commutePreference'>;

interface CommutePreferenceSelectProps extends CommutePreferenceSelectPropsBase{
  setCommuteAddress: (address: PreferredAddress) => void;
  autoFocus?: boolean;
  onBlur?: () => void;
  setCommuteSubStep?: (s: boolean) => void;
  isContentByCommuteTypeAllowed?: boolean;
}

export const PreferenceSelect: React.FC<CommutePreferenceSelectProps> = (props) => {
  const {
    setCommuteAddress,
    commutePreference,
    autoFocus,
    onBlur,
    setCommuteSubStep,
    isContentByCommuteTypeAllowed,
  } = props;
  const { text, commuteType } = commutePreference;

  const [ searchStr, setSearchStr ] = useState<string>(text);
  const { t } = useLocale();
  const theme = useTheme();

  let commuteContentByPreference = null;

  if (isContentByCommuteTypeAllowed) {
    switch (commuteType) {
      case 'bus':
        commuteContentByPreference = <CommuteSoonBus />;
        break;
      case 'train':
        commuteContentByPreference = <WizardCommuteTrain />;
        break;
      case 'car':
      case 'walk':
      default:
        commuteContentByPreference = null;
        break;
    }
  }

  return (
    <GenericCommutePreferenceForm
      autoFocus={(text && commuteType) ? false : autoFocus}
      onBlur={onBlur}
      withoutCommuteType
      searchStr={searchStr}
      inputIcon={<LocationIcon width={24} height={24}/>}
      accentColor={theme.colors.brand.main}
      onSetSearchStr={setSearchStr}
      commuteContentByPreference={commuteContentByPreference}
      onAddressSelect={(address: PreferredAddress) => {
        setCommuteAddress(address);

        if (isContentByCommuteTypeAllowed && setCommuteSubStep && address) {
          setCommuteSubStep(true);
        }
      }}
      placeholder={t('homePage.wizard.commute.placeholder')}
      initialPreference={{ ...commutePreference, location: null }}
    />
  );
};

const mapDispatchToProps = {
  setCommuteAddress: setCommuteAddressAction,
  setCommuteSubStep: setCommuteSubStepAction,
};

export default connect(homepageWizardSelector, mapDispatchToProps)(PreferenceSelect);
