import React, { useEffect } from 'react';

import { Slider } from 'ds/components/slider';
import { useLocale } from 'locale';
import WalkIcon from 'assets/svg/transportation/walk.svg';
import BikeIcon from 'assets/svg/transportation/bike.svg';
import CarIcon from 'assets/svg/transportation/car.svg';
import { SmallText } from 'ds/components/typography';
import { CommutePreference } from 'utils/entities';

import { CommuteDistanceText, CommuteTimeToTrainWrapper, CommuteTimeToTrainLine } from '../styled';


const getCarDrivingTime = (value: number): [ number, number ] => {
  if (value <= 1000) return [ 0, 10 ];
  if (value <= 2000) return [ 5, 15 ];
  if (value <= 3000) return [ 7, 20 ];
  if (value <= 5000) return [ 10, 25 ];
  if (value <= 10000) return [ 12, 30 ];
  return [ null, 20 ];
};

const getMinutesHours = (floatHours: number): [ number, number ] => {
  const hours = Math.trunc(floatHours);
  const minutes = Math.round(parseFloat((floatHours - hours).toFixed(2)) * 60);

  return [ minutes, hours ];
};

const MIN_METERS = 250;
const MAX_METERS = 15000;
const DEFAULT_METERS = 1000;
const STEP = 250;
const WALK_METERS_PER_HOUR = 5000;
const BIKE_METERS_PER_HOUR = 15000;
const ONE_KILOMETER = 1000;

interface CommuteTrainProps {
  commutePreference: CommutePreference;
  setCommuteMaxDistanceTrain: (maxDistance: number) => void;
}

export const CommuteTrain: React.FC<CommuteTrainProps> = (props) => {
  const { setCommuteMaxDistanceTrain, commutePreference: { maxCommute: { distanceMaxTrainCommute } } } = props;
  const { t } = useLocale();
  const distanceMaxTrainCommuteWithDefault = distanceMaxTrainCommute || DEFAULT_METERS;
  useEffect(() => {
    setCommuteMaxDistanceTrain(distanceMaxTrainCommuteWithDefault);
  }, []);
  const [ carMinTime, carMaxTime ] = getCarDrivingTime(distanceMaxTrainCommuteWithDefault);
  const [ walkMinutes, walkHours ] = getMinutesHours(distanceMaxTrainCommuteWithDefault / WALK_METERS_PER_HOUR);
  const [ bikeMinutes ] = getMinutesHours(distanceMaxTrainCommuteWithDefault / BIKE_METERS_PER_HOUR);

  return (
    <>
      <CommuteDistanceText>
        {t('commutePopup.train.distance', { value: distanceMaxTrainCommuteWithDefault / ONE_KILOMETER })}
      </CommuteDistanceText>
      <Slider
        value={distanceMaxTrainCommuteWithDefault}
        min={MIN_METERS}
        max={MAX_METERS}
        step={STEP}
        onChange={setCommuteMaxDistanceTrain}
      />
      <CommuteTimeToTrainWrapper>

        <CommuteTimeToTrainLine>
          <WalkIcon />
          <SmallText>
            {t('commutePopup.train.by.walk', { minutes: walkMinutes, hours: walkHours })}
          </SmallText>
        </CommuteTimeToTrainLine>

        <CommuteTimeToTrainLine>
          <BikeIcon />
          <SmallText>
            {t('commutePopup.train.by.bike', { minutes: bikeMinutes })}
          </SmallText>
        </CommuteTimeToTrainLine>

        <CommuteTimeToTrainLine>
          <CarIcon />
          <SmallText>
            {t('commutePopup.train.by.car', { carMinTime, carMaxTime })}
          </SmallText>
        </CommuteTimeToTrainLine>

      </CommuteTimeToTrainWrapper>
    </>
  );
};
