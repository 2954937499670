import styled from '@emotion/styled';
import { Text, H2, SmallText } from 'ds/components/typography';
import { Button } from 'ds/components/button';


export const Link = styled.a`
  ${({ theme }) => `
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: ${theme.colors.neutrals.white};
    > svg {
      width: 20px;
      height: 40px;
      margin-right: ${theme.spacing(0.5)};
      flex-shrink: 0;
      path {
        fill: ${theme.colors.neutrals.white};
      }
    }
  `}
`;

export const SlideTextRoot = styled.div<{ backgroundColor?: string }>`
  ${({ backgroundColor, theme }) => `
    svg {
      margin-right: ${theme.spacing(.5)};
      display: inline-block;
      vertical-align: middle;
      path {
        fill: ${theme.colors.neutrals.white};
      }
    }
    span {
      ${backgroundColor ? `
        white-space: pre;
        padding: 2px 5px;
        border-radius: ${theme.borderRadius.micro};
        background-color: ${backgroundColor};
      ` : ''}
    }
  `}
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme: { spacing, media: { showAt } } }) => showAt({ from: 3 })`margin-bottom: ${spacing(14)};`}
`;

export const BackgroundImage = styled.div<{ backgroundImage: string }>`
  ${({ theme, backgroundImage }) => `
    position: relative;
    background-image: linear-gradient(to bottom, ${theme.colors.brand.darker}, rgba(1, 94, 97, 0) 90px), url(${backgroundImage});
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  `}
`;

export const SearchSectionSideImg = styled.img`
  position: absolute;
  max-width: 300px;
  height: auto;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  ${({ theme }) => theme.media.showAt({ to: 2 })`display: none;`};
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    max-width: 280px;
  `};
`;

export const SearchSectionFooterImg = styled.img`
  height: 131px;
  width: auto;
`;

export const SearchWrapper = styled.div`
  margin: 0 auto;
  position: relative;

  ${({ theme: { media: { showAt } } }) => showAt({ at: 1 })`height: 400px; padding: 88px 24px 0;`}
  ${({ theme: { media: { showAt } } }) => showAt({ at: 2 })`height: 500px; padding: 99px 40px 0;`}
  ${({ theme: { media: { showAt } } }) => showAt({ at: 3 })`height: 500px; width: 864px; padding: 99px 0 0;`}
  ${({ theme: { media: { showAt } } }) => showAt({ from: 4 })`height: 544px; width: 1144px; padding: 143px 0 0;`}
`;

export const LandingHeading = styled(H2.withComponent('h1'))`
  ${({ theme }) => `
    line-height: 1.15;
    text-shadow: 0 2px 40px rgba(15, 50, 51, 0.05);
    margin-bottom: ${theme.spacing(8)};
    color: ${theme.colors.neutrals.white};
  `}
  ${({ theme: { media: { showAt } } }) => showAt({ from: 2, to: 3 })`
    font-size: 45px;
    line-height: 1.15;
  `};
  ${({ theme: { media: { showAt } } }) => showAt({ from: 4 })`
    font-size: 52px;
    line-height: 1.15;
  `};
  ${({ theme: { media: { showAt } } }) => showAt({ at: 1 })`text-align:center;`};
`;

export const LandingSubHeading = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.white};
    z-index: 1;
    max-width: 568px;
    margin-top: ${theme.spacing(6)};
  `}
`;

export const SearchSectionFooter = styled.div`
  ${({ theme }) => `
    width: 100%;
    margin-top: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
    padding: 0 ${theme.spacing(2)} ${theme.spacing(3)};
    border-bottom: 1px solid ${theme.colors.neutrals.grey8};
  `}
`;

export const SearchSectionFooterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MobileLandingSubHeading = styled(Text.withComponent('h2'))`
  ${({ theme }) => `
    text-shadow: 0 2px 40px rgba(15, 50, 51, 0.05);
    line-height: 1.8;
    color: ${theme.colors.brand.main};
    padding-left: ${theme.spacing(1.5)};
    span {
      white-space: pre-wrap;
    }
  `}
`;

export const MobileWelcomeLargeButton = styled(Button)`
  ${({ theme }) => `
    margin-top: ${theme.spacing(1)};
    svg {
      margin-right: ${theme.spacing(0.5)};
      path {
        fill: ${theme.colors.neutrals.white};
      }
    }
  `}
`;

export const HighlightedText = styled(H2.withComponent('div'))`
  ${({ theme }) => `
    border-radius: ${theme.borderRadius.micro};
    padding: 2px 5px;
  `}
`;

export const PlayControl = styled(SmallText)`
  ${({ theme }) => `
    margin-left: ${theme.spacing(1)};
    background: rgba(0, 0, 0, 0.2);
    border-radius: ${theme.borderRadius.large};
    padding: 0 6px;
    color: ${theme.colors.neutrals.white};
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  `}
`;

export const SliderControls = styled.div`
  display: flex;

  ${({ theme }) => theme.media.showAt({ to: 2 })`
    align-items: center;
    justify-content: center;
    margin: ${theme.spacing(1)} auto;
  `}
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    position: absolute;
    bottom: 48px;
    left: 50%;
    transform: translateX(-50%);
  `}
`;

export const PagerWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    border-radius: ${theme.borderRadius.large};
    padding: 1px 4px 2px;
    direction: ltr;
  `}
`;
