import { useLocale } from 'locale';
import React from 'react';
import { State } from 'store/state';
import { connect } from 'react-redux';
import AnimatedWizard from './AnimatedWizard';
import {
  homepageWizardCurrentStepSelector,
  homepageWizardOpenSelector,
  homePageWizardDealTypeSelector,
} from 'store/state/homepageWizard/selectors';
import { StepNavigator } from './StepNavigator';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { ConnectedBuyRentStep } from './steps/BuyRentStep';
import { ConnectedPurchasingReasonStep } from './steps/PurchasingReasonStep';
import { ConnectedPriceAndRooms } from './steps/PriceAndRooms';
import { WizardWrapper, MobileWizardWrapper, DESKTOP_WIDTH } from './styled';
import CommuteWizardContent from './common/commute';
import Modal from 'ds/components/modal';
import { HomepageWizardStep } from 'store/state/homepageWizard';


interface WizardProps {
  steps: HomepageWizardStep[];
  currentStepIndex: number;
  isWizardOpen: boolean;
}

const Wizard: React.FC<WizardProps> = (props) => {
  const { t } = useLocale();
  const isMobile = useScreenBreakpoint() < 3;
  const { steps, currentStepIndex, isWizardOpen } = props;

  const currentStep = steps[currentStepIndex];
  let content: React.ReactNode = null;
  const title: string = t('wizard.step.title', { stepType: currentStep, isMobile });

  switch (currentStep) {
    case HomepageWizardStep.DealType:
      content = <ConnectedBuyRentStep />;
      break;

    case HomepageWizardStep.Filters:
      content = <ConnectedPriceAndRooms />;
      break;

    case HomepageWizardStep.Commute:
      content = <CommuteWizardContent isAutoFocus={isMobile} />;
      break;

    case HomepageWizardStep.PurchasingReason:
      content = <ConnectedPurchasingReasonStep />;
      break;
  }

  if (isMobile && isWizardOpen) {
    return (
      <Modal
        isOpen
        hideCloseButton
        onClose={null}
        isFullWidthMainContent
      >
        <MobileWizardWrapper>
          <StepNavigator
            title={title}
            currentStepIndex={currentStepIndex}
            steps={steps}
          >
            {content}
          </StepNavigator>
        </MobileWizardWrapper>
      </Modal>
    );
  }

  return (
    <AnimatedWizard desktopWidth={DESKTOP_WIDTH}>
      <WizardWrapper>
        <StepNavigator
          title={title}
          currentStepIndex={currentStepIndex}
          steps={steps}
        >
          {content}
        </StepNavigator>
      </WizardWrapper>
    </AnimatedWizard>
  );
};

const mapStateToProps = (state: State) => ({
  currentStepIndex: homepageWizardCurrentStepSelector(state),
  isWizardOpen: homepageWizardOpenSelector(state),
  dealType: homePageWizardDealTypeSelector(state),
});

export default connect(mapStateToProps)(Wizard);
