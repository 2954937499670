import { styled } from 'ds';

export const Root = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(11)} 0;
    width: 100%;
    .carousel-shadow-root div.view-pager-rail {
      padding-right: 0;
    }
    .slide-btn[dir="next"] {
      left: -${theme.spacing(2)};
    }
    .slide-btn[dir="prev"] {
      right: -${theme.spacing(2)};
    }
  `};
`;

export const Header = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(2)};
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.spacing(1.5)};
    > div:last-of-type {
      padding-right: 52px;
    }
  `}
`;

