import React from 'react';
import ChevronLeft from 'assets/svg/chevron-left.svg';
import { Button } from 'ds/components/button';

export const HomepageButton: React.FC = ({ children }) => {
  return (
    <Button data-auto="homepage-button" size="large" iconPosition="end">
      {children}
      <ChevronLeft />
    </Button>
  );
};
