import React from 'react';
import { Circle, InternalCircle, Text } from './styled';
import { useLocale } from 'locale';
import { CommuteType } from 'utils/entities';
import { transportationTypeToIcons } from 'components/widget/transportation/TimeToGetToItem';

interface CategoryProps {
  name: CommuteType;
  handleClick: (name: CommuteType) => void;
  commuteType: CommuteType;
}

const Category: React.FC<CategoryProps> = ({ name, handleClick, commuteType }) => {
  const { t } = useLocale();
  const isActive = commuteType === name;
  const title = <Text isActive={isActive}>{t('homePage.wizard.commute.transportCategories', { name })}</Text>;

  return (
    <div
      data-auto={`commute-type-${name}`}
      data-auto-active={isActive}
    >
      <Circle isActive={isActive} onClick={() => handleClick(name)} data-auto={`commute-circle-button-${name}`}>
        <InternalCircle isActive={isActive}>
          {transportationTypeToIcons[name]}
        </InternalCircle>
      </Circle>
      {title}
    </div>
  );
};

export default Category;
