import React from 'react';

import CommuteEmptyStateIcon from 'assets/svg/commute-empty-state.svg';
import { useLocale } from 'locale';

import {
  CommuteSoonWrapper,
  CommuteSoonIcon,
  CommuteEmptyStateText,
} from '../styled';


export const CommuteSoonBus = () => {
  const { t } = useLocale();

  return (
    <CommuteSoonWrapper>
      <CommuteSoonIcon>
        <CommuteEmptyStateIcon width={40} height={40} />
      </CommuteSoonIcon>
      <CommuteEmptyStateText>{t('commutePopup.soon.bus')}</CommuteEmptyStateText>
    </CommuteSoonWrapper>
  );
};
