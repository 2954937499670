import styled from '@emotion/styled';
import { SmallText, Text } from 'ds/components/typography';
import { CleanButton } from 'ds/components/button';
import { colorToAlpha } from 'helpers/insight';


export const DESKTOP_WIDTH = 560;

export const BaseStepSubHeader = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey2};
    margin-bottom: ${theme.spacing(7)};
  `}
  ${({ theme }) => theme.media.showAt({ to: 1 })`
    color: ${theme.colors.neutrals.grey3};
    margin-bottom: ${theme.spacing(5)};
  `}
`;

export const StepButtonContainer = styled.div<{ inTest?: boolean, isWizard?: boolean }>`
  display: flex;
  div + div, button + button {
    margin: ${({ theme }) => theme.isRTL ? `0 ${theme.spacing(2)} 0 0` : `0 0 0 ${theme.spacing(2)}`};
  }
  margin-bottom: ${({ theme, inTest, isWizard }) => theme.spacing(inTest && isWizard ? 8 : 10)};
`;

export const BuyRentRootInner = styled.div`
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    padding-top: ${theme.spacing(1)};
  `}
`;

export const PurchasingReasonContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    margin-top: ${theme.spacing(7)};
    > div {
      margin-bottom: ${theme.spacing(1)};
    }
  `}
  ${({ theme }) => theme.media.showAt({ to: 1 })`
    margin-top: 0;
  `}
`;

export const PurchasingReasonButton = styled(CleanButton)<{ selected: boolean }>`
  ${({ theme, selected }) => `
    border-radius: ${theme.borderRadius.extraXLarge};
    border: solid 1px ${selected ? theme.colors.brand.lighter : theme.colors.neutrals.grey6};
    color: ${selected ? theme.colors.brand.main : theme.colors.neutrals.grey1};
    background-color: ${selected ? colorToAlpha(theme.colors.brand.lightest, 0.7) : theme.colors.neutrals.white};
    outline: none;
    cursor: pointer;
    &:hover:not([disabled]) {
      background-color: ${selected ? colorToAlpha(theme.colors.brand.lightest, 0.7) : theme.colors.neutrals.white};
    }
  `}
`;

export const PriceAndRoomsRoot = styled.div`
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    padding-top: ${theme.spacing(4)};
  `}
`;

export const RoomsRangeContainer = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.spacing(6)};
  `}
`;

export const RangeSelectContainer = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.spacing(2)};
  `}
`;

export const WizardLabel = styled(SmallText)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
    margin-bottom: ${theme.spacing(1.5)};
  `}
  ${({ theme }) => theme.media.showAt({ to: 1 })`
    color: ${theme.colors.neutrals.grey3};
    margin-bottom: ${theme.spacing(2)};
  `}
`;

export const PriceRangeLabel = styled(Text)`
  ${({ theme }) => `
    display: block;
    text-align: center;
    color: ${theme.colors.neutrals.grey1};
    margin-bottom: ${theme.spacing(3)};
  `}
  ${({ theme }) => theme.media.showAt({ to: 1 })`
    margin-bottom: ${theme.spacing(2)};
    text-align: ${theme.isRTL ? 'right' : 'left'};
  `}
`;
