import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { useLocale } from 'locale';
import { CleanButton, Button } from 'ds/components/button';
import {
  homepageWizardSelector,
} from 'store/state/homepageWizard/selectors';
import {
  skipStep as skipStepAction,
  submitWizard as submitWizardAction,
  takeStepBack as takeStepBackAction,
  nextStep as nextStepAction,
  setWizardOpen as setWizardOpenAction,
} from 'store/state/homepageWizard/actions';
import { ButtonsContainer } from './styled';
import { ShowAt } from 'consts/breakpoints';
import { createSelector } from 'reselect';
import {
  isPriceAndRoomsDirtySelector,
  isPurchasingReasonDirtySelector,
} from './selectors';
import { State } from 'store/state';
import { HomepageWizardStep, WIZARD_STEPS_IL } from 'store/state/homepageWizard';
import { last, first } from 'lodash';
import { DealType } from 'utils/entities';


export enum MoveForwardActionType {
  SKIP = 'skip',
  NEXT = 'next',
  SUBMIT = 'submit',
  NONE = 'none',
}

interface WizardNavigationButtonsProps {
  shouldUseOnClose: boolean;
  takeStepBack: () => void;
  skipStep: () => void;
  nextStep: () => void;
  submitWizard: () => void;
  forwardAction: MoveForwardActionType;
  hideBackAction: boolean;
  isDirty: boolean;
  onClose: () => void;
}

interface WizardNavigationButtonsOwnProps {
  currentStep: HomepageWizardStep;
  isMobile: boolean;
}

const WizardNavigationButtonsView: React.FC<WizardNavigationButtonsProps> = ({
  takeStepBack,
  onClose,
  skipStep,
  nextStep,
  submitWizard,
  forwardAction,
  hideBackAction,
  isDirty,
  shouldUseOnClose,
}) => {
  const { t } = useLocale();

  const renderForwardButtonFromAction = (action: MoveForwardActionType, isMobile: boolean): ReactNode => {
    switch (action) {
      case MoveForwardActionType.NEXT:
        return (
          <CleanButton
            size="large"
            onClick={nextStep}
            data-auto="next-button"
          >
            {t('homePage.wizard.button.next')}
          </CleanButton>
        );
      case MoveForwardActionType.SKIP:
        return (
          <CleanButton
            size="large"
            onClick={skipStep}
            data-auto="skip-button"
          >
            {t('homePage.wizard.button.skip')}
          </CleanButton>
        );
      case MoveForwardActionType.SUBMIT:
        return isDirty ? (
          <Button
            size={isMobile ? 'large' : 'extraLarge'}
            onClick={submitWizard}
            data-auto="submit-button"
          >
            {t('homePage.wizard.button.results')}
          </Button>
        ) : (
          <CleanButton
            size="large"
            onClick={submitWizard}
            data-auto="submit-button"
          >
            {t('homePage.wizard.button.skip')}
          </CleanButton>
        );
      case MoveForwardActionType.NONE:
      default:
        return null;
    }
  };

  if (hideBackAction && !forwardAction) {
    return null;
  }

  return (
    <ButtonsContainer hideBackAction={hideBackAction}>
      {hideBackAction ? null : (
        <CleanButton
          size="large"
          mode="secondary"
          onClick={shouldUseOnClose ? onClose : takeStepBack}
          data-auto="back-button"
        >
          {t('homePage.wizard.button.back')}
        </CleanButton>
      )}
      <ShowAt to={2}>
        {(isMobile) => renderForwardButtonFromAction(forwardAction, isMobile)}
      </ShowAt>
    </ButtonsContainer>
  );
};

const getForwardAction = (isDirty: boolean, isLastStep: boolean, enableSkip: boolean) => {
  if (isLastStep) return MoveForwardActionType.SUBMIT;
  else if (isDirty) return MoveForwardActionType.NEXT;
  else if (enableSkip) return MoveForwardActionType.SKIP;
  return MoveForwardActionType.NONE;
};

const makeNavigationSelector = () => createSelector([
  (state: State, props: WizardNavigationButtonsOwnProps) => props,
  isPriceAndRoomsDirtySelector,
  isPurchasingReasonDirtySelector,
  homepageWizardSelector,
], (
  { currentStep, isMobile },
  isPriceAndRoomsDirty,
  isPurchasingReasonDirty,
  { dealType, docId, commutePreference, sort }
) => {
  const steps = WIZARD_STEPS_IL;
  let isLastStep = last(steps) === currentStep;
  const isFirstStep = first(steps) === currentStep;

  let isDirty: boolean = null;
  const shouldUseOnClose: boolean = isFirstStep;
  let enableSkip = true;

  switch (currentStep) {
    case HomepageWizardStep.DealType:
      if (dealType && !isMobile) {
        isDirty = Boolean(docId);
      }
      enableSkip = Boolean(dealType);
      break;

    case HomepageWizardStep.Filters:
      isDirty = Boolean(isPriceAndRoomsDirty);
      break;

    case HomepageWizardStep.Commute:
      const { location, maxCommute } = commutePreference;
      isLastStep = dealType === DealType.Rent;
      isDirty = Boolean(location || maxCommute.distanceMaxTrainCommute);
      break;

    case HomepageWizardStep.PurchasingReason:
      isDirty = isPurchasingReasonDirty;
      break;

    default:
      break;
  }
  return {
    forwardAction: getForwardAction(isDirty, isLastStep, enableSkip),
    hideBackAction: null,
    isDirty,
    shouldUseOnClose,
  };
});

const makeMapStateToProps = () => {
  const navigationSelector = makeNavigationSelector();

  return (state: State, props: WizardNavigationButtonsOwnProps) => ({
    ...navigationSelector(state, props),
    ...homepageWizardSelector(state),
  });
};

const mapDispatchToProps = {
  takeStepBack: takeStepBackAction,
  skipStep: skipStepAction,
  submitWizard: submitWizardAction,
  nextStep: nextStepAction,
  onClose: () => setWizardOpenAction({ isOpen: false }),
};

export const WizardNavigationButtons = connect(makeMapStateToProps, mapDispatchToProps)(WizardNavigationButtonsView);
