import styled from '@emotion/styled';

export const Wrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    margin-${theme.isRTL ? 'left' : 'right'}: ${theme.spacing(4)};
    justify-content: space-between;
  `};
  ${({ theme }) => theme.media.showAt({ to: 1 }) `
    margin: 0;
    padding: ${theme.spacing(1)} ${theme.spacing(1)} 0;
  `};
`;
