import styled from '@emotion/styled';
import { colorToAlpha } from 'helpers/insight';
import { SmallText } from 'ds/components/typography';

export const Circle = styled.div<{ isActive: boolean }>`
  height: 72px;
  width: 72px;
  ${({ theme, isActive }) => `
    border-radius: ${theme.borderRadius.round};
    border: 1px solid ${isActive ? theme.colors.brand.lighter : theme.colors.neutrals.grey8};
    background-color: ${isActive ? colorToAlpha(theme.colors.brand.lightest, 0.7) : theme.colors.neutrals.white};
    &:hover div {
      background-color: ${theme.colors.brand.main};
    }
    &:hover svg {
      path {
        fill: ${theme.colors.neutrals.white};
      }
    }
  `};

  position: relative;
  margin: 0 auto 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.media.showAt({ to: 1 })`
    width: 48px;
    height: 48px;
  `}
`;

export const InternalCircle = styled.div<{ isActive: boolean }>`
  width: 56px;
  height: 56px;
  border-radius: ${({ theme }) => theme.borderRadius.round};
  background-color: ${({ theme, isActive }) => isActive ? theme.colors.brand.main : theme.colors.neutrals.grey9};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    width: 24px;
    height: 24px;
    > path {
      fill: ${({ theme, isActive }) => isActive ? theme.colors.neutrals.white : theme.colors.neutrals.grey2};
    }
  }
  ${({ theme }) => theme.media.showAt({ to: 1 })`
    width: 36px;
    height: 36px;
    > svg {
      width: 16px;
      height: 16px;
    }
  `}
`;

export const Text = styled(SmallText)<{ isActive: boolean }>`
  ${({ theme, isActive }) => `
  color: ${isActive ? theme.colors.brand.main : theme.colors.neutrals.grey3};
  text-align: center;
`}`;

