import { styled } from 'ds';
import { Text } from 'ds/components/typography';


export const TitleHintWizard = styled(Text)<{inTest?: boolean}>`
  color: ${({ theme }) => theme.colors.neutrals.grey3};
  margin-bottom: ${({ theme, inTest }) => theme.spacing(inTest ? 9 : 7)};
  max-width: 380px;
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin-bottom: ${theme.spacing(5)};
    max-width: 300px;
  `}
`;

export const CategoryWrapper = styled.div<{ big?: boolean }>`
  margin-bottom: ${({ big, theme }) => big ? theme.spacing(7) : theme.spacing(5)};
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin-bottom: ${theme.spacing(theme.isRTL ? 4 : 5)};
  `}
`;

export const CategoryLabelWizard = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey1};
    margin-bottom: ${theme.spacing(2)};
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 }) `
    ${theme.isRTL ? `margin-bottom: ${theme.spacing(1)}` : ''};
  `}
`;

export const CategoryLabelWizardText = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey3};
    margin-bottom: ${theme.spacing(2)};
  `};
`;

export interface TitleWizardProps {
  smallBottomMargin?: boolean;
}

export const TitleWizardWrapper = styled.div<{ isDesktop?: boolean, inTest?: boolean } & TitleWizardProps>`
  ${({ theme, smallBottomMargin, isDesktop, inTest }) => `
    letter-spacing: -0.71px;
    color: ${theme.colors.neutrals.grey1};
    margin-bottom: ${smallBottomMargin ? theme.spacing(isDesktop ? 1.5 : (inTest ? 2 : 1)) : theme.spacing(isDesktop ? (inTest ? 9 : 7) : 5)};
  `}
`;

export const SortOptions = styled.div<{ step?: number, inTest?: boolean, withLineBreak?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  ${({ withLineBreak }) => withLineBreak ? `
    flex-direction: column;
    align-items: baseline;
  ` : ''}

  ${({ theme, inTest }) => inTest ? `
    > * {
      margin: ${theme.isRTL ? `0 0 ${theme.spacing(2)} ${theme.spacing(2)}` : `0 ${theme.spacing(2)} ${theme.spacing(2)} 0`};
    }
  ` : `
    > * {
      margin: ${theme.isRTL ? `0 0 ${theme.spacing(1.5)} ${theme.spacing(1)}` : `0 ${theme.spacing(1)} ${theme.spacing(1.5)} 0`};
    }
  `}
`;

export const RentBuyContainer = styled.div<{ inTest?: boolean, isWizard?: boolean }>`
  display: flex;
  div + div, button + button {
    margin: ${({ theme }) => theme.isRTL ? `0 ${theme.spacing(2)} 0 0` : `0 0 0 ${theme.spacing(2)}`};
  }
  margin-bottom: ${({ theme, inTest, isWizard }) => theme.spacing(inTest && isWizard ? 8 : 10)};
`;

export const WizardContentWrapper = styled.div`
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    height: 100%;
  `}
`;

export const WizardContentInner = styled.div`
  ${({ theme }) => theme.media.showAt({ from: 3 }) `
    ${theme.isRTL ? '' : `margin-top: ${theme.spacing(7)};`}
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    padding-top: ${theme.isRTL ? theme.spacing(2) : theme.spacing(1)};
  `};
`;

export const DesktopHomePageWrapper = styled.div`
  ${({ theme }) => `
    margin-top: -${theme.spacing(4)};
  `}
`;

export const BlogSectionBg = styled.div`
  background: ${({ theme }) => theme.colors.neutrals.grey10};
`;

export const BlogSectionWrapper = styled.div`
  margin: 0 auto ${({ theme }) => theme.spacing(6)};
  background: ${({ theme }) => theme.colors.neutrals.grey10};
  ${({ theme }) => theme.media.showAt({ at: 1 })`
    div.carousel-shadow-root div.view-pager-rail,
    .home-page__blog-header {
      padding: 0 ${theme.spacing(2)};
    }
  `};
  ${({ theme }) => theme.media.showAt({ at: 2 })`padding: 0 ${theme.spacing(5)};`};
  ${({ theme }) => theme.media.showAt({ at: 3 })`max-width: 864px;`};
  ${({ theme }) => theme.media.showAt({ from: 4 })`max-width: 1144px;`};
`;
