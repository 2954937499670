import { Link } from 'components/link';
import { SortValue } from 'components/listing-sort/types';
import config from 'config';
import { Route } from 'config/routes';
import { ShowAt } from 'consts/breakpoints';
import { useImageUrlBuilder } from 'hooks/useImageUrlBuilder';
import { useLocale } from 'locale';
import React from 'react';
import { DealType } from 'utils/entities';
import { HomepageButton } from './HomepageButton';
import { PowersortCardWrapper, PowersortInnerContent, PowersortInputWrapper, PowersortLabel, PowersortTotal } from './styled';

interface PowersortCardProps {
  imageUrl: string;
  powersortOptions: SortValue[];
  powerSortLabel: string;
  total: number;
}

const PowersortCard: React.FC<PowersortCardProps> = ({
  imageUrl,
  powerSortLabel,
  total,
  powersortOptions,
}) => {
  const { t, formatNumber } = useLocale();

  const constructImageURI = useImageUrlBuilder();
  const configureTotal = total && formatNumber(total);

  return (
    <PowersortCardWrapper data-auto={powersortOptions[0].toString().replace(/,/g, '-')}>
      <PowersortInnerContent>
        <ShowAt from={3} to={5}>
          {isDesktop => {
            const url = constructImageURI(imageUrl, {
              height: 264,
            });
            return isDesktop ? <img src={url} alt={powerSortLabel} /> : null;
          }}
        </ShowAt>
        <PowersortTotal>
          {t('homePage.bulletins.sections.forSale')}
        </PowersortTotal>
        <PowersortLabel>{powerSortLabel}</PowersortLabel>
        <PowersortInputWrapper>
          <Link
            data-auto={`${powersortOptions[0].toString().replace(/,/g, '-')}-link`}
            routeName={Route.Search}
            routeParams={{
              term: [ config.cityTerm ],
              dealType: DealType.Buy,
              sort: powersortOptions,
              tracking_search_source: 'homepage_persona',
            }}
            hideLinkParams
          >
              <HomepageButton>
                {t('homePage.bulletins.sections.appropriate', { total: configureTotal  })}
              </HomepageButton>
          </Link>
        </PowersortInputWrapper>
      </PowersortInnerContent>
    </PowersortCardWrapper>
  );
};


export default PowersortCard;
