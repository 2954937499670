import withEvents from 'analytics/withEvents';
import { ITranslate } from 'locale';
import React from 'react';
import { connect } from 'react-redux';
import { HPSlide } from 'store/sagas/apiService/types';
import { State } from 'store/state';
import { safeHPBannerSelector } from 'store/state/domainData/selectors/homePageBanner';
import { Slider, SliderProps } from './Slider';
import { BreakpointValue } from 'consts/breakpoints';


const SliderWithEvents = withEvents<SliderProps>(sendEvent => ({
  onSubtitleLinkClick() {
    sendEvent('madad_click', 'madad');
  },
}))(Slider);


interface SliderWrapperProps {
  banner: HPSlide[];
  t: ITranslate;
  screenBreakpoint: BreakpointValue;
}

type SliderOwnProps = Pick<SliderWrapperProps, 't' | 'screenBreakpoint'>;

const mapStateToProps = (state: State, { t, screenBreakpoint }: SliderOwnProps) => ({
  banner: safeHPBannerSelector(state, t, screenBreakpoint),
});

export default connect<Pick<SliderWrapperProps, 'banner'>, never, SliderOwnProps>(mapStateToProps, null)((props) => {
  const {
    banner,
    children,
    headerScrollToRef,
  } = props;

  if (!banner) return null;

  return (
    <SliderWithEvents banner={banner} headerScrollToRef={headerScrollToRef}>
      {children}
    </SliderWithEvents>
  );
});
