import React from 'react';
import { useLocale } from 'locale';
import { CommuteRushHourWrapper } from './styled';
import { CommuteRushHour } from '../styled';
import { CheckboxField } from 'ds/components/input/CheckboxField';

interface RushHourFieldProps {
  rushHour: boolean;
  setCommuteRushHour: (rushHour: boolean) => void;
}

export const RushHourField: React.FC<RushHourFieldProps> = (props) => {
  const {
    setCommuteRushHour,
    rushHour,
  } = props;
  const { t } = useLocale();
  return (
    <CommuteRushHourWrapper>
      <CommuteRushHour checked={rushHour}>
        <CheckboxField
          label={t('commutePopup.rushHour')}
          checked={rushHour}
          onClick={() => setCommuteRushHour(!rushHour)}
        />
      </CommuteRushHour>
    </CommuteRushHourWrapper>
  );
};
