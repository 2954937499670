import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocale } from 'locale';
import {
  WizardLabel,
  PriceRangeLabel,
  RoomsRangeContainer,
  RangeSelectContainer,
  PriceAndRoomsRoot,
} from './styled';
import {
  homePageWizardDealTypeSelector,
  homePageWizardPriceRangeSelector,
  homePageWizardRoomsRangeSelector,
} from 'store/state/homepageWizard/selectors';
import {
  setPriceRange,
  setRoomsRange,
} from 'store/state/homepageWizard/actions';
import { DealType, MarketplaceType } from 'utils/entities';
import { State } from 'store/state';
import { SliderRange } from 'ds/components/slider';
import { config } from 'components/filters/config';
import { RangeTuple, RoomOption } from 'components/filters/types';
import RangeSelect from 'ds/components/input/RangeSelect';
import { ShowAt } from 'consts/breakpoints';
import { marketplaceSelector } from 'store/state/selectors/router';


interface PriceAndRoomsProps {
  onPriceChange: (range: RangeTuple<number>) => void;
  onRoomsChange: (range: RangeTuple<RoomOption>) => void;
  dealType: DealType;
  priceRange: RangeTuple<number>;
  roomsRange: RangeTuple<RoomOption>;
}

const PriceAndRooms: React.FC<PriceAndRoomsProps> = (props) => {
  const {
    onPriceChange,
    dealType,
    priceRange,
    onRoomsChange,
    roomsRange,
  } = props;
  const { t, formatMoney } = useLocale();
  const marketplace = useSelector(marketplaceSelector);
  const isCommercial = marketplace === MarketplaceType.Commercial;

  const { roomsOptions, budgetByDealType } = config[marketplace];

  const options = roomsOptions.map((value) => ({
    value,
    label: t('filters.roomsOptions', { value, isCommercial }),
  }));

  const [ initialFrom, initialTo ] = budgetByDealType[dealType].initialBudgetValue;
  const [ currentFrom, currentTo ] = priceRange;

  return (
    <ShowAt at={1}>
      {isMobile => {
        const priceRangeLabel = t('filters.priceLongValue', {
          isActive: false,
          priceFrom: formatMoney(currentFrom || initialFrom),
          priceTo: formatMoney(currentTo || initialTo),
          isMaxedOut: initialTo === currentTo,
          isMobile,
        });
        return (
          <PriceAndRoomsRoot>
            {isMobile ? null : (
              <WizardLabel>
                {t('homePage.wizard.filters.priceRangeLabel')}
              </WizardLabel>
            )}
            <PriceRangeLabel>{priceRangeLabel}</PriceRangeLabel>
            <SliderRange
              {...budgetByDealType[dealType].budgetSliderProps}
              values={priceRange[0] === null ? budgetByDealType[dealType].initialBudgetValue : priceRange}
              onChange={onPriceChange}
              mode="ltr"
            />
            <RoomsRangeContainer>
              <WizardLabel weight={isMobile ? 'bold' : 'normal'}>
                {t('homePage.wizard.filters.bedroomsLabel')}
              </WizardLabel>
              <RangeSelectContainer>
                <RangeSelect
                  values={roomsRange}
                  options={options}
                  onChange={onRoomsChange}
                />
              </RangeSelectContainer>
            </RoomsRangeContainer>
          </PriceAndRoomsRoot>
        );
      }}
    </ShowAt>
  );
};

const mapStateToProps = (state: State) => ({
  dealType: homePageWizardDealTypeSelector(state),
  priceRange: homePageWizardPriceRangeSelector(state),
  roomsRange: homePageWizardRoomsRangeSelector(state),
});

const mapDispatchToProps = {
  onPriceChange: setPriceRange,
  onRoomsChange: setRoomsRange,
};

export const ConnectedPriceAndRooms = connect(mapStateToProps, mapDispatchToProps)(PriceAndRooms);
