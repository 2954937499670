import React, { useState } from 'react';
import {
  StyledLink,
} from 'components/static-blog-section/styled';
import * as Styled from '../styled';
import { Root, Header } from './styled';
import { useLocale } from 'locale';
import { BaseCarousel } from 'ds/components/carousels/base-carousel';
import BlogCard from 'components/blog-card';
import GlassesIcon from 'assets/svg/glasses.svg';
import { BlogCarouselProps, isEmpty, getDirection } from '../helper';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { noop } from 'lodash';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';

const blogHref = 'https://www.madlan.co.il/blog/';

export const HomePageBlogSection: React.FC<BlogCarouselProps> = ({ articles, loading, onCarouselScroll = noop }) => {
  const { t } = useLocale();
  const [ slideIndex, setSlideIndex ] = useState(0);
  const carouselSectionNode = React.useRef<HTMLDivElement>(null);
  const mediaBreakpoint = useScreenBreakpoint();
  const isMobile = mediaBreakpoint <= 2;
  const onCarouselSlideChange = (idx: number) => {
    onCarouselScroll(getDirection(slideIndex, idx), isMobile);
    setSlideIndex(idx);
  };

  const [ sectionInView, setSectionInView ] = useState(false);
  useIntersectionObserver(([ entry ]) => {
    setSectionInView(entry.isIntersecting);
  }, [ carouselSectionNode ], { threshold: 1 });

  if (isEmpty(articles, loading)) return null;

  return (
    <Root ref={carouselSectionNode} data-auto="articles-carousel">
      <Header className="home-page__blog-header">
        <Styled.InfoWrapper>
          <GlassesIcon height={40} width={40} />
          <Styled.InfoBox>
            <Styled.Title data-auto="articles-carousel-title" weight="bold">{t('commercial.homepage.blog.title')}</Styled.Title>
            <Styled.SubTitle data-auto="articles-carousel-subtitle">{t('commercial.homepage.blog.subtitle')}</Styled.SubTitle>
          </Styled.InfoBox>
        </Styled.InfoWrapper>
        <StyledLink href={blogHref} target="_blank" withArrow weight="normal">{t('landingPage.blogSection.link')}</StyledLink>
      </Header>
      <BaseCarousel
        hideShadow
        entryContentDXOverride={24}
        hideControls={isMobile}
        onSlideIndexChange={onCarouselSlideChange}
        activeCarouselSlide={slideIndex}
      >
          {articles.map((el, k, arr) => (
            <BlogCard
              key={`${k}${el.title}`}
              articleId={el.id}
              rootNode={carouselSectionNode}
              sectionInView={sectionInView}
              title={el.title}
              imageUrl={el.image}
              developerId={el.owner.id}
              developerName={el.owner.name}
              postUrl={el.link}
              withLeftMargin={k !== arr.length - 1}
            />
          ))}
      </BaseCarousel>
    </Root>
  );
};
