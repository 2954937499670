import React from 'react';
import styled from '@emotion/styled';
import { useLocale } from 'locale';
import { TextLabel, Text } from 'ds/components/typography';

import TrainIcon from 'assets/svg/transportation/metro.svg';
import BikeIcon from 'assets/svg/transportation/bike.svg';
import CarIcon from 'assets/svg/transportation/car.svg';
import WalkIcon from 'assets/svg/transportation/walk.svg';
import BusIcon from 'assets/svg/transportation/bus.svg';
import PlusIcon from 'assets/svg/transportation/plus.svg';
import {
  Circle,
  GreyStrip,
} from './styled';
import { LimitedText } from 'components/widget/common/styled';
import { colorToAlpha } from 'helpers/insight';
import { InsightType, CommuteType, commuteTypeDefaultOrder } from 'utils/entities';
import { getHoursAndMinutes } from 'locale/formatDate';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootAction } from 'store/state';
import { setCommutePopupStatus } from 'store/state/app/actions';
import { CommutePopupStatus } from 'components/commute-popup/types';
import { useImageUrlBuilder } from 'hooks/useImageUrlBuilder';
import { TRANSPORTATION_HUBS_TYPE } from 'store/state/selectors/insights/transportation/utils';


export const transportationTypeToIcons: Record<CommuteType, JSX.Element> = {
  commute: <TrainIcon/>,
  train: <TrainIcon/>,
  bike: <BikeIcon/>,
  car: <CarIcon/>,
  walk: <WalkIcon/>,
  bus: <BusIcon />,
};

export interface TimeToGetToProps {
  destination?: TRANSPORTATION_HUBS_TYPE | string;
  imgUrl?: string;
  time?: Record<CommuteType, number>;
  isEmptyTime?: boolean;
}

interface TimeToGetToItemProps extends TimeToGetToProps {
  onShowCommutePopup: () => void;
}

export const TimeToGetToItem: React.FunctionComponent<TimeToGetToItemProps> = ({ destination, imgUrl, time, isEmptyTime, onShowCommutePopup }) => {
  const { t } = useLocale();
  const constructImageURI = useImageUrlBuilder();
  const imgSrc = constructImageURI(imgUrl, { height: 96 * 2, width: 80 * 2, fit: 'outside' });

  return (
    <Section data-auto="commute-item">
      <ImageBlock imgUrl={imgSrc} clickable={isEmptyTime} onClick={() => isEmptyTime ? onShowCommutePopup() : null} data-auto={isEmptyTime ? 'add-commute' : ''}>
        {isEmptyTime ? (
          <Circle><PlusIcon/></Circle>
        ) : null}
      </ImageBlock>

      <ContentWrapper data-auto="commute-content-wrapper">
        <Title>
          <LimitedText maxWidth={155}>
            <TextLabel caps weight="bold">{isEmptyTime ? t('transportationPage.addYourCommute') : destination}</TextLabel>
          </LimitedText>
        </Title>
        <TimeSlotWrapper data-auto="commute-time-slot-wrapper">
          {commuteTypeDefaultOrder.map(type => (
            <TimeSlotCell isEmptyTime={isEmptyTime} key={type}>
              {transportationTypeToIcons[type]}
              {isEmptyTime || !time[type]
                ? <GreyStrip/>
                : <Text>
                    {t('transportationPage.timeSlot', getHoursAndMinutes(time[type]))}
                  </Text>
              }
            </TimeSlotCell>
          ))}
        </TimeSlotWrapper>
      </ContentWrapper>
    </Section>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onShowCommutePopup: () => dispatch(setCommutePopupStatus(CommutePopupStatus.Open)),
});

export default connect(null, mapDispatchToProps)(TimeToGetToItem);

export const ImageBlock = styled.div<{imgUrl: string, clickable?: boolean}> `
  width: 80px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props => props.theme.borderRadius.tiny};
  ${props => props.clickable ? 'cursor: pointer;' : ''}
  ${props => props.imgUrl ? `
    background-image: url('${props.imgUrl}');
    background-position: center center;
    background-size: cover;
  ` : `
    background: ${colorToAlpha(props.theme.colors.insights[InsightType.Transportation], 0.08)};
  `}

  > div > svg > path {
      fill: ${props => props.theme.colors.insights.transportation};
  }
`;

export const ContentWrapper = styled.div `
  display: flex;
  flex: 1;
  flex-direction: column;
  ${props => props.theme.isRTL ? 'padding-right' : 'padding-left' }: 16px;
`;

export const TimeToGetToRow = styled.div`
  position: relative;
  display: flex;

  ${props => props.theme.media.showAt({ to: 2 }) `
    display: block;
    > div {
      margin: 0;
    }
  `}
`;

const Section = styled.div`
  display: flex;
  border-radius: ${props => props.theme.borderRadius.tiny};
  background: ${({ theme }) => theme.colors.neutrals.white};
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  border: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
  ${({ theme }) => theme.isRTL ? `
    padding: 16px 16px 16px 24px;
    margin-left: 8px;
  ` : `
    padding: 16px 24px 16px 16px;
    margin-right: 8px;
  `}

  ${props => props.theme.media.showAt({ from: 3 })`
    min-width: 303px;
    max-width: 340px;
    height: 128px;
  `};
  ${props => props.theme.media.showAt({ at: 5 })`
    max-width: 326px;
  `};
`;

const Title = styled.div`
  padding-bottom: 24px;
`;

export const TimeSlotWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

interface ITimeSlotCell {
  isEmptyTime: boolean;
}

export const TimeSlotCell = styled.div<ITimeSlotCell>`
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: flex-start;

  &:nth-of-type(-n + 2) { // select first 2 elements
    margin-bottom: 8px;
  }

  ${props => props.theme.media.showAt({ at: 3 }) `flex-wrap: wrap;`}

  svg {
    ${props => props.theme.isRTL ? 'margin-left: 8px;' : 'margin-right: 8px;'};
    fill: ${(props) => props.theme.colors.neutrals[props.isEmptyTime ? 'grey7' : 'grey1']};
    >  path {
      fill: ${(props) => props.theme.colors.neutrals[props.isEmptyTime ? 'grey7' : 'grey1']};
    }
  }
`;
