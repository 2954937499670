import React from 'react';
import { connect } from 'react-redux';
import { useLocale } from 'locale';
import { IAutocompleteEntry } from 'utils/entities';
import { homepageWizardSelector } from 'store/state/homepageWizard/selectors';
import { setDocId as setDocIdAction, setSearchStr as setSearchStrAction } from 'store/state/homepageWizard/actions';
import AddressAutocompleteInput from 'components/autocomplete/single-location-autocomplete';
import { AddressAutocompleteSource } from 'components/autocomplete/types';
import { HomepageWizardState } from 'store/state/homepageWizard';
import { ISetDocIdMeta } from 'store/state/homepageWizard/types';
import LocationIcon from 'assets/svg/location-gallery.svg';
import { CompletionListGroup, config as completionConfig } from 'components/commute-popup/CompletionTypeConfig';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { CategoryLabelWizard, WizardContentWrapper } from '../styled';

type AddressWizardContentPropsBase = Pick<HomepageWizardState, 'searchStr'>;

interface AddressWizardContentProps extends AddressWizardContentPropsBase {
  setSearchStr: (str: string) => void;
  setDocId: (address: IAutocompleteEntry, meta: ISetDocIdMeta) => void;
  autoNavigateToNextStep?: boolean; // navigates user to next step after autocomplete selection
  hideSubtitle?: boolean;
  source: AddressAutocompleteSource;
}

const AddressWizardContentView: React.FC<AddressWizardContentProps> = (props) => {
  const { setSearchStr, setDocId, searchStr, autoNavigateToNextStep, hideSubtitle, source } = props;
  const { t } = useLocale();
  const range = useScreenBreakpoint();
  const isMobile = range <= 2;

  const handleLocationInput = (value: IAutocompleteEntry): void => {
    if (value) {
      setDocId(value, { navigateToNextStep: autoNavigateToNextStep });
    }
  };

  return (
    <WizardContentWrapper>
      {hideSubtitle ? null : (
        <CategoryLabelWizard caps weight="bold">
          {t('homePage.wizard.address.subTitle')}
        </CategoryLabelWizard>
      )}
      <AddressAutocompleteInput
        showClearButton
        optionsCount={5}
        showType
        source={source}
        searchStr={searchStr}
        completionTypes={completionConfig[CompletionListGroup.Places]}
        onSearchStrChange={setSearchStr}
        inputIcon={<LocationIcon width={24} height={24} />}
        placeholder={t('homePage.wizard.placeholder')}
        onChange={handleLocationInput}
        initialValue={null}
        suggestionItemIcon={isMobile ? undefined : null}
      />
    </WizardContentWrapper>
  );
};

export const AddressWizardContent = connect(
  homepageWizardSelector,
  {
    setSearchStr: setSearchStrAction,
    setDocId: setDocIdAction,
  }
)(AddressWizardContentView);
