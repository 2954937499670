import { connect } from 'react-redux';
import { State } from 'store/state';

import { homePageWizardCommuteSelector } from 'store/state/homepageWizard/selectors';
import { CommuteSubStepCar } from 'components/commute-popup/components/CommuteSubStepCar';
import { CommuteSubStepWalk } from 'components/commute-popup/components/CommuteSubStepWalk';
import { CommuteTrain } from 'components/commute-popup/components/CommuteTrain';
import {
  setCommuteMaxTimeCar,
  setCommuteMaxTimeWalk,
  setCommuteRushHour,
  setCommuteMaxDistanceTrain,
} from 'store/state/homepageWizard/actions';


const mapStateToProps = (state: State) => ({
  commutePreference: homePageWizardCommuteSelector(state),
});

export const WizardCommuteSubStepWalk = connect(mapStateToProps, { setCommuteMaxTimeWalk })(CommuteSubStepWalk);
export const WizardCommuteSubStepCar = connect(mapStateToProps, {
  setCommuteMaxTimeCar,
  setCommuteRushHour,
})(CommuteSubStepCar);

export const WizardCommuteTrain = connect(mapStateToProps, { setCommuteMaxDistanceTrain })(CommuteTrain);
