import styled from '@emotion/styled';


export const Wrapper = styled.div`
  margin: 0 auto;
  ${props => props.theme.media.showAt({ at: 1 })`padding: 0 16px;`};
  ${props => props.theme.media.showAt({ at: 2 })`padding: 0 40px;`};
  ${props => props.theme.media.showAt({ at: 3 })`max-width: 864px;`};
  ${props => props.theme.media.showAt({ from: 4 })`max-width: 1144px;`};
`;

export const FooterWrapper = styled.div`
  ${props => props.theme.media.showAt({ at: 1 })`margin: 0 0 ${props.theme.spacing(11)};`};
  ${props => props.theme.media.showAt({ from: 2 })`margin: 0 0 ${props.theme.spacing(16)};`};
`;
