import { createSelector } from 'reselect';
import {
  homePageWizardPriceRangeSelector,
  homePageWizardRoomsRangeSelector,
  homePageWizardDealTypeSelector,
  homePageWizardPurchasingReasonSelector,
} from 'store/state/homepageWizard/selectors';
import { config } from 'components/filters/config';
import { marketplaceSelector } from 'store/state/selectors/router';

const initialPriceCompare = (initial: number, current: number) => initial !== current && current !== null;

export const isPriceAndRoomsDirtySelector = createSelector(
  homePageWizardPriceRangeSelector,
  homePageWizardRoomsRangeSelector,
  homePageWizardDealTypeSelector,
  () => config,
  marketplaceSelector,
  (priceRange, roomsRange, dealType, conf, marketplace) => {
    if (!dealType) return false;
    const { budgetByDealType } = conf[marketplace];
    const [ initialFrom, initialTo ] = budgetByDealType[dealType].initialBudgetValue;
    const [ priceFrom, priceTo ] = priceRange;
    const pricesDirty = initialPriceCompare(initialFrom, priceFrom) || initialPriceCompare(initialTo, priceTo);
    const roomsDirty = roomsRange.some(Boolean);

    return pricesDirty || roomsDirty;
  }
);

export const isPurchasingReasonDirtySelector = createSelector(homePageWizardPurchasingReasonSelector, (reason) => Boolean(reason));
