import React, { useRef, useState, useContext } from 'react';
import { DecodedRouteParams, NavigateToFn, Route } from 'config/routes';
import { useLocale } from 'locale';
import ChevronIcon from 'assets/svg/chevron-left.svg';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { UserAgentContext } from 'hooks/useImageUrlBuilder';
import { isIOS } from 'utils/uaMatchers';
import { HintBlock, LinkText, LinkTextBefore, StaticWrapper, Wrapper } from './styled';
import { connect } from 'react-redux';
import * as actions from 'store/state/router/actions';
import { WithContentSlotBottomPadding, WithContentSlotSidePaddingContainer, WithContentSlotTopPadding } from 'ds/components/Layout';
import { Link } from 'components/link';

interface MortgageOfficesSearchPageEntryProps {
  navigateTo: NavigateToFn;
  navigateProps?: {
    routeName: Route;
    routeParms: DecodedRouteParams;
  };
}

const BannerContent: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const { t } = useLocale();
  return (
    <>
      <LinkText weight="medium">
        <HintBlock>{t('mortgage.modeB.common.hint')}</HintBlock>
        <LinkTextBefore weight="bold">{t('mortgageOfficesEntry.linkTextBefore')}</LinkTextBefore>
        {t('mortgageOfficesEntry.linkText', { isMobile })}
        <ChevronIcon />
      </LinkText>
    </>
  );
};

const ResidentialListingMortgageOfficesSearchPageEntry: React.FC<MortgageOfficesSearchPageEntryProps> = ({ navigateProps: { routeName, routeParms } }) => {
  const isMobile = useScreenBreakpoint() <= 2;

  return (
    <WithContentSlotTopPadding style={{ background: '#f3f3f3' }}>
      <WithContentSlotBottomPadding>
        <WithContentSlotSidePaddingContainer>
          <Link
            routeName={routeName}
            routeParams={routeParms}
            style={{ textDecoration: 'none' }}
          >
            <StaticWrapper data-auto="personal-assistant-unitpage-link">
              <BannerContent isMobile={isMobile} />
            </StaticWrapper>
          </Link>
        </WithContentSlotSidePaddingContainer>
      </WithContentSlotBottomPadding>
    </WithContentSlotTopPadding>
  );
};

export const ResidentialListingMortgageOfficesSearchPageEntryConnected = connect(null, {
  navigateTo: actions.navigateTo,
})(ResidentialListingMortgageOfficesSearchPageEntry);

export const MortgageOfficesSearchPageEntry: React.FC<MortgageOfficesSearchPageEntryProps> = ({ navigateTo }) => {
  const [ isFixedVisible, setIsFixedVisible ] = useState(false);
  const wrapperRef = useRef(null);
  const isMobile = useScreenBreakpoint() <= 2;
  const userAgent = useContext(UserAgentContext);
  const isIOSDevice = isIOS(userAgent);

  const onIntersect = ([ entry ]: IntersectionObserverEntry[]) => {
    if (entry.isIntersecting) {
      setIsFixedVisible(false);
    }
    else {
      setIsFixedVisible(true);
    }
  };

  useIntersectionObserver(onIntersect, [ wrapperRef ], { rootMargin: '0px 0px 300px 0px', threshold: [ 0, 0.5 ] });

  const handleEntryClick = () => {
    navigateTo(Route.MortgageOfficesSearchPage, {});
  };

  return (
    <>
      <Wrapper data-auto="personal-assistant-link" onClick={handleEntryClick} ref={wrapperRef}>
        <BannerContent isMobile={isMobile} />
      </Wrapper>
      {isFixedVisible ? (
        <Wrapper onClick={handleEntryClick} isFixed isIOSDevice={isIOSDevice}>
          <BannerContent isMobile={isMobile} />
        </Wrapper>
      ) : null}
    </>
  );
};
