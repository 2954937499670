import React from 'react';
import * as Styled from './styled';
import { useImageUrlBuilder } from 'hooks/useImageUrlBuilder';
import { useScreenBreakpoint } from 'consts/breakpoints';

export interface AIDBannerProps {
  bannerClick?: (link: string) => void;
  rootRef?: React.RefObject<HTMLAnchorElement>;
}

export const AIDBanner: React.FC<AIDBannerProps> = ({ bannerClick, rootRef }) => {
  const constructImageURI = useImageUrlBuilder();
  const screenBreakpoint = useScreenBreakpoint();

  const isDesktop = screenBreakpoint >= 2;
  const desktopPath = 'banners/aig/hp_desktopv3.jpg';
  const mobilePath = 'banners/aig/hp_mobile.png';
  const path = isDesktop ? desktopPath : mobilePath;
  const fullImageURL = constructImageURI(path, {});

  const link = 'https://join.aig.co.il/insurance/home?utm_source=madlan&utm_medium=cpc&utm_campaign=madlan_apartment_sep&utm_content=home_page';

  return (
    <Styled.Root ref={rootRef} target="_blank" rel="nofollow" href={link}>
      <Styled.BannerImage onClick={() => bannerClick(link)} src={fullImageURL} />
    </Styled.Root>
  );
};
