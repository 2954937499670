import styled from '@emotion/styled';

export const Root = styled.a({
  display: 'block',
});

export const BannerImage = styled.img({
  width: '100%',
  display: 'block',
  aspectRatio: 'auto 1325 / 96',
});
