import React  from 'react';
import { Wrapper } from './styled';
import Category from './category';
import { CommuteType, commuteTypeDefaultOrder } from 'utils/entities';
import { connect } from 'react-redux';
import { homepageWizardSelector } from 'store/state/homepageWizard/selectors';
import {
  setCommuteRushHour as setCommuteRushHourAction,
  setCommuteType as setCommuteTypeAction,
} from 'store/state/homepageWizard/actions';
import { CommutePreferenceSelectPropsBase } from '../index';

interface TransportCategoriesProps extends CommutePreferenceSelectPropsBase {
  setCommuteType: (type: CommuteType) => void;
  setCommuteRushHour: (rushHour: boolean) => void;
}

const TransportCategories: React.FC<TransportCategoriesProps> = ({ setCommuteType, setCommuteRushHour, commutePreference: { commuteType } }) => {
  const handleClick = (name: CommuteType) => {
    if (name !== commuteType) {
      setCommuteType(name);
    }
  };
  return (
    <Wrapper>
      {commuteTypeDefaultOrder.map(name =>
        (<Category
          key={name}
          name={name}
          commuteType={commuteType}
          handleClick={handleClick}
        />)
      )}
    </Wrapper>
  );
};

export default connect(
  homepageWizardSelector, {
    setCommuteType: setCommuteTypeAction,
    setCommuteRushHour: setCommuteRushHourAction,
  }
)(TransportCategories);
