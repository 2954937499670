import React from 'react';
import { Link as BaseRouteLink } from 'react-router5';
import { Route } from 'config/routes';
import { useLocale } from 'locale';
import styled from '@emotion/styled';
import { Text, SmallText } from 'ds/components/typography';
import { useScreenBreakpoint, BreakpointValue } from 'consts/breakpoints';
import ArrowLeftIcon from 'assets/svg/chevron-left.svg';
import { useImageUrlBuilder } from 'hooks/useImageUrlBuilder';
import withEvents from 'analytics/withEvents';


const MADAD_LABEL = '/madad/madad_banner_2023-24.png';
const MADAD_COVER = '/madad/madad24_cover@1x.png';

const Root = styled.section<{ bgUrl: string }>`
  ${({ theme, bgUrl }) => `
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    min-height: 320px;
    background: url("${bgUrl}") lightgray center;
    border-radius: ${theme.borderRadius.small};
    margin-bottom: ${theme.spacing(10)};
  `}
`;

const MobileRoot = styled(Root)(({ theme }) => ({
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(2),
}));

const Group = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;

  ${({ theme }) => theme.media.showAt({ from: 3 })`
    padding: 0 ${theme.spacing(7)};
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    align-items: center;
  `}
`;

const Header = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.white};
    margin-bottom: ${theme.spacing(2)};
    white-space: pre-wrap;
    line-height: 1.17;
    font-size: 24px;
  `}
`;

const Description = styled(Text.withComponent('p'))`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.white};
    white-space: pre-wrap;
    max-width: 410px;
  `}
`;

const SmallDescription = styled(SmallText.withComponent('p'))`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.white};
    max-width: 248px;
    text-align: center;
  `}
`;

const RouteLink = styled(BaseRouteLink)`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${theme.colors.label.yellow};
    > svg {
      width: 12px;
      height: 24px;
      margin-right: ${theme.spacing(0.5)};
      path {
        fill: ${theme.colors.label.yellow};
      }
    }
  `}
`;

const RouteLinkSpacer = styled.div`
  ${({ theme }) => theme.media.showAt({ from: 2 })`
    margin-top: ${theme.spacing(3)};
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    margin-top: ${theme.spacing(2.5)};
  `}
`;

const screenToImageWidth: Record<BreakpointValue, number> = {
  1: 222,
  2: 316,
  3: 316,
  4: 316,
  5: 316,
};

const Img = styled.img`
  flex-shrink: 0;
  height: auto;
`;

const ImageWrapper = styled.div`
  flex-shrink: 0;
`;


interface MadadBannerProps {
  onLinkClick?: () => void;
}

const MadadBanner: React.FC<MadadBannerProps> = (props) => {
  const { onLinkClick } = props;
  const { t } = useLocale();
  const screenBreakpoint = useScreenBreakpoint();
  const constructImageURI = useImageUrlBuilder();
  const isMobile = screenBreakpoint <= 2;

  const madadLabelUrl = constructImageURI(MADAD_LABEL);
  const labelStyles: React.CSSProperties = { width: screenToImageWidth[screenBreakpoint] };

  const handleLinkClick = () => onLinkClick && onLinkClick();

  if (isMobile) {
    return (
      <MobileRoot bgUrl={constructImageURI(MADAD_COVER)}>
        <Group>
          <Header data-acc-heading="h2" weight="bold">{t('homePage.madadBanner.header')}</Header>
          <SmallDescription>{t('homePage.madadBanner.description')}</SmallDescription>
          <RouteLinkSpacer>
            <RouteLink
              routeName={Route.MadadPage}
              routeParams={{ source: 'homepage_banner' }}
              target="_blank"
              onClick={handleLinkClick}
              data-auto="homepage-madad-banner-link"
            >
              <SmallText weight="bold">{t('homePage.madadBanner.link')}</SmallText>
              <ArrowLeftIcon />
            </RouteLink>
          </RouteLinkSpacer>
          <Img
            src={madadLabelUrl}
            style={labelStyles}
          />
        </Group>
      </MobileRoot>
    );
  }

  return (
    <Root bgUrl={constructImageURI(MADAD_COVER)}>
      <Group>
        <Header data-acc-heading="h2" weight="bold">{t('homePage.madadBanner.header')}</Header>
        <Description>{t('homePage.madadBanner.description')}</Description>
        <RouteLinkSpacer>
          <RouteLink
            routeName={Route.MadadPage}
            routeParams={{ source: 'homepage_banner' }}
            target="_blank"
            onClick={handleLinkClick}
            data-auto="homepage-madad-banner-link"
          >
            <Text weight="bold">{t('homePage.madadBanner.link')}</Text>
            <ArrowLeftIcon />
          </RouteLink>
        </RouteLinkSpacer>
      </Group>
      <ImageWrapper>
        <Img
          src={madadLabelUrl}
          style={labelStyles}
        />
      </ImageWrapper>
    </Root>
  );
};

export default withEvents<MadadBannerProps>((sendEvent) => ({
  onLinkClick() {
    sendEvent('madad_click', 'madad');
  },
}))(MadadBanner);
