import React from 'react';

import { CommuteType } from 'utils/entities';
import ChevronLeft from 'assets/svg/chevron-left.svg';

import { IconWrapper, Breadcrumbs, CloseSubStep, StyledSubStepWrapper, TypeIconWrapper } from './styled';
import { commuteTypeToIcon } from '../GenericCommutePreferenceForm';


interface CommuteSubStepWrapperProps {
  type: CommuteType;
  commuteAddress: string;
  setCommuteSubStep?: (s: boolean) => void;
}

export const CommuteSubStepWrapper: React.FC<CommuteSubStepWrapperProps> = (props) => {
  const { type, commuteAddress, children, setCommuteSubStep } = props;

  return (
    <StyledSubStepWrapper>
      <Breadcrumbs>
        <TypeIconWrapper>
          {commuteTypeToIcon[type]}
        </TypeIconWrapper>
        <IconWrapper><ChevronLeft /></IconWrapper>
        <CloseSubStep onClick={() => setCommuteSubStep(false)}>{commuteAddress}</CloseSubStep>
      </Breadcrumbs>
      {children}
    </StyledSubStepWrapper>
  );
};
