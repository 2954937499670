import React from 'react';
import { connect } from 'react-redux';
import { useLocale } from 'locale';
import {
  BaseStepSubHeader,
  StepButtonContainer,
  BuyRentRootInner,
} from './styled';
import { homePageWizardSearchAddressSelector, homePageWizardDealTypeSelector } from 'store/state/homepageWizard/selectors';
import {
  setDealType as setDealTypeAction,
  submitWizard as submitWizardAction,
} from 'store/state/homepageWizard/actions';
import { DealType, IAutocompleteEntry } from 'utils/entities';
import { ISetDealTypeMeta } from 'store/state/homepageWizard/types';
import { State } from 'store/state';
import { ShowAt } from 'consts/breakpoints';
import RadioChip from 'ds/components/input/RadioChip';
import { AddressWizardContent } from '../common/AddressWizardContent';
import { AddressAutocompleteSource } from 'components/autocomplete/types';


interface BuyRentStepProps {
  setDealType: (dealType: DealType, meta: ISetDealTypeMeta) => void;
  searchAddress: IAutocompleteEntry;
  submitWizard: () => void;
  dealType: DealType;
}

const BuyRentStep: React.FC<BuyRentStepProps> = ({ setDealType, searchAddress, submitWizard, dealType }) => {
  const { t } = useLocale();
  const { name, type } = searchAddress || { name: null, type: null };
  const handleRent = () => {
    setDealType(DealType.Rent, { autoNavigateToNextStep: searchAddress !== null });
  };

  const handleBuy = () => {
    setDealType(DealType.Buy, { autoNavigateToNextStep: searchAddress !== null });
  };

  return (
    <ShowAt at={1}>
      {isMobile => (
        <>
          <BuyRentRootInner>
            <BaseStepSubHeader>{t('homePage.wizard.buyRent.subTitle', { name, type, isMobile })}</BaseStepSubHeader>
            <StepButtonContainer>
              <RadioChip
                data-auto="buy-button"
                label={t('homePage.wizard.button.buy')}
                fullWidth
                size="extraLarge"
                mode={dealType === DealType.Buy ? 'selected' : undefined}
                onClick={handleBuy}
              />
              <RadioChip
                data-auto="rent-button"
                label={t('homePage.wizard.button.rent')}
                fullWidth
                size="extraLarge"
                mode={dealType === DealType.Rent ? 'selected' : undefined}
                onClick={handleRent}
              />
            </StepButtonContainer>
          </BuyRentRootInner>
          {dealType ? (
            <AddressWizardContent autoNavigateToNextStep source={AddressAutocompleteSource.HomePageTop} />
          ) : null}
        </>
      )}

    </ShowAt>
  );
};


const mapStateToProps = (state: State) => ({
  searchAddress: homePageWizardSearchAddressSelector(state),
  dealType: homePageWizardDealTypeSelector(state),
});

const mapDispatchToProps = {
  setDealType: setDealTypeAction,
  submitWizard: submitWizardAction,
};

export const ConnectedBuyRentStep = connect(mapStateToProps, mapDispatchToProps)(BuyRentStep);
