import React, { useEffect } from 'react';

import { Slider } from 'ds/components/slider';
import { useLocale } from 'locale';

import { CommuteDistanceText } from '../styled';
import { CommutePreference } from 'utils/entities';
import { getTimeNullToMax, getTimeMaxToNull } from './utils';


const MIN_MINUTES = 5;
const MAX_MINUTES = 35; // 30+
const DEFAULT_MINUTES = 10;
const STEP = 5;

interface CommuteSubStepWalkProps {
  commutePreference: CommutePreference;
  setCommuteMaxTimeWalk: (maxTime: number) => void;
}

export const CommuteSubStepWalk: React.FC<CommuteSubStepWalkProps> = (props) => {
  const { setCommuteMaxTimeWalk, commutePreference: { maxCommute: { timeMaxWalkCommute } } } = props;
  const computedTime = getTimeNullToMax(timeMaxWalkCommute, MAX_MINUTES) || DEFAULT_MINUTES;
  const { t } = useLocale();
  const setCommuteMaxTimeWalkWithNull = (time: number) => setCommuteMaxTimeWalk(getTimeMaxToNull(time, MAX_MINUTES));

  useEffect(() => {
    setCommuteMaxTimeWalkWithNull(computedTime);
  }, []);

  return (
    <>
      <CommuteDistanceText>{t('commutePopup.walk.minutes', { minutes: computedTime })}</CommuteDistanceText>
      <Slider
        value={computedTime}
        min={MIN_MINUTES}
        max={MAX_MINUTES}
        step={STEP}
        onChange={setCommuteMaxTimeWalkWithNull}
      />
    </>
  );
};

