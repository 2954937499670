import { HomePageBlogSection } from './blog-section';
import { connect } from 'react-redux';
import { articlesResponseSelector } from 'store/state/mutationsResponse/selectors';
import { State } from 'store/state';
import { createSelector } from 'reselect';
import { BlogCarouselProps } from '../helper';
import withEvents from 'analytics/withEvents';

const articlesSelector = createSelector([
  (state: State) => articlesResponseSelector(state, 'homepage'),
], (v) => (v && v.data && v.data.getArticles) || []);

const articlesLoadingSelector = createSelector([
  (state: State) => articlesResponseSelector(state, 'homepage'),
], (v) => v && v.loading);

const BlogSectionWithEvents = withEvents<BlogCarouselProps>(sendEvent => ({
  onCarouselScroll: (direction: 'left' | 'right', isMobile: boolean) => sendEvent('article_carousel_scroll', 'article', {
    event: {
      direction,
      action: isMobile ? 'swipe' : 'click',
    },
  }),
}))(HomePageBlogSection);

const mapStateToProps = (state: State) => ({
  articles: articlesSelector(state),
  loading: articlesLoadingSelector(state),
});

export default connect(mapStateToProps)(BlogSectionWithEvents);
