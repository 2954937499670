import React, { useEffect } from 'react';
import Content from './IL';
import { HeaderWrapper, HEADER_HEIGHT } from 'ds/components/Layout';
import Navigation from 'components/navigation';
import { noop } from 'lodash';
import { DesktopHomePageWrapper } from 'screens/HomePage/styled';
import AIDBanner from './aig-banner';
import { useGlobalEventListener } from 'hooks/useGlobalEventListener';
import { isServer } from 'utils';
import { debounce } from 'lodash';

const HomePage: React.FC = () => {

  const [ additionalCss, setAdditionalCss ] = React.useState<React.CSSProperties>({ top: undefined });
  const bannerRef = React.useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useGlobalEventListener(isServer() ? null : window, 'scroll', debounce(() => {
    if (bannerRef.current) {
      if (window.scrollY > bannerRef.current.offsetHeight) setAdditionalCss({ top: `${HEADER_HEIGHT}px` });
      else {
        setAdditionalCss({ top: `${bannerRef.current.offsetHeight + HEADER_HEIGHT - window.scrollY}px` });
      }
    }
  }, 300));

  return (
    <>
      <AIDBanner rootRef={bannerRef} />
      <HeaderWrapper>
        <Navigation
          tooltipStyle={{ ...additionalCss, marginTop: '0' }}
          isMobileMapViewActive={false}
          setMobileMapViewActive={noop}
          isNavigationSubheaderVisible={false}
        />
      </HeaderWrapper>
      <DesktopHomePageWrapper>
        <Content />
      </DesktopHomePageWrapper>
    </>
  );
};

export default HomePage;
