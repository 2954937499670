import React, { useEffect } from 'react';

import { Slider } from 'ds/components/slider';
import { useLocale } from 'locale';
import { CommutePreference } from 'utils/entities';
import { RushHourField } from './RushHour';
import { CommuteDistanceText } from '../styled';
import { getTimeNullToMax, getTimeMaxToNull } from './utils';


const MIN_MINUTES = 10;
const MAX_MINUTES = 70; // 60+
const DEFAULT_MINUTES = 30;
const STEP = 10;

interface CommuteSubStepCarProps {
  commutePreference: CommutePreference;
  setCommuteMaxTimeCar: (maxTime: number) => void;
  setCommuteRushHour: (rushHour: boolean) => void;
}

export const CommuteSubStepCar: React.FC<CommuteSubStepCarProps> = (props) => {
  const {
    setCommuteMaxTimeCar,
    commutePreference,
    setCommuteRushHour,
  } = props;
  const { maxCommute: { timeMaxCarCommute }, rushHour } = commutePreference;
  const computedTime = getTimeNullToMax(timeMaxCarCommute, MAX_MINUTES) || DEFAULT_MINUTES;
  const { t } = useLocale();
  const setCommuteMaxTimeCarWithNull = (time: number) => setCommuteMaxTimeCar(getTimeMaxToNull(time, MAX_MINUTES));

  useEffect(() => {
    setCommuteMaxTimeCarWithNull(computedTime);
    if (!rushHour) setCommuteRushHour(true);
  }, []);

  return (
    <>
      <CommuteDistanceText>
        {t('commutePopup.car.minutes', { minutes: computedTime })}
      </CommuteDistanceText>
      <Slider
        value={computedTime}
        min={MIN_MINUTES}
        max={MAX_MINUTES}
        step={STEP}
        onChange={setCommuteMaxTimeCarWithNull}
      />
      <RushHourField rushHour={rushHour} setCommuteRushHour={setCommuteRushHour} />
    </>
  );
};
