import withEvents from 'analytics/withEvents';
import LocationIcon from 'assets/svg/location-gallery.svg';
import SearchIcon from 'assets/svg/search3.svg';
import AddressAutocompleteInput from 'components/autocomplete/single-location-autocomplete';
import { AddressAutocompleteSource } from 'components/autocomplete/types';
import { CompletionListGroup, config as completionConfig } from 'components/commute-popup/CompletionTypeConfig';
import config from 'config';
import { NavigateToFn, Route } from 'config/routes';
import { useLocale } from 'locale';
import React, { useRef, useState } from 'react';
import { AppMode } from 'store/state/selectors/router';
import { CompletionType, DealType, IAutocompleteEntry } from 'utils/entities';
import {
  buildSearchPayload,
  getResidentialNavigationParams,
  IAutocompleteTrackingEventMeta,
} from 'components/search-widget/helpers';
import {
  InputWrapper,
  SearchBlockRoot,
  SearchButton,
  SearchTypeSelectLabel,
  SearchTypeSelectWrapper,
  Separator,
} from './styled';
import { Actions } from 'downshift';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { LocalStorage } from 'utils/localStorage';
import { IS_WIZARD_COMPLETED } from 'consts/localStorageKeys';
import { noop } from 'lodash';
import { useABTests } from 'hooks/useABTests';
import { RemoveWizard } from 'config/abTests';


enum SearchType {
  Buy = 'unitBuy',
  CheckAddress = 'checkAddress',
}

enum TabCategory {
  Residential = 'residential_marketplace',
  Professional = 'professional_page',
  InfoPage = 'info_page',
}

interface SearchBlockProps {
  navigate: NavigateToFn;
  onSearch?: (meta?: IAutocompleteTrackingEventMeta) => void;
  handleSearch?: (input: IAutocompleteEntry) => void;
  handleNullSearch?: () => void;
  onSetSearchType?: (type: SearchType) => void;
}

const MAX_RESULTS = 5;

const SearchBlock: React.FC<SearchBlockProps> = (props) => {
  const { t } = useLocale();
  const [ searchStr, setSearchStr ] = useState<string>('');
  const [ searchType, setSearchType ] = useState<SearchType>(SearchType.Buy);
  const [ suggestions, setSuggestions ] = useState<IAutocompleteEntry[]>();
  const downshiftRef = useRef<Actions<IAutocompleteEntry>>(null);
  const { removeWizard } = useABTests();
  const mediaBreakpoint = useScreenBreakpoint();
  const isMobile = mediaBreakpoint <= 2;

  const { navigate, onSearch, handleSearch, handleNullSearch, onSetSearchType = noop } = props;

  const isWizardCompleted = removeWizard === RemoveWizard.modeB ? true : (LocalStorage.get(IS_WIZARD_COMPLETED) || false);

  const completionTypes = completionConfig[searchType === SearchType.Buy
    ? CompletionListGroup.HomepageBuy
    : CompletionListGroup.Default];

  const isEmptySearch = handleSearch ? !searchStr : false;

  const handleAutocompleteChange = (input: IAutocompleteEntry) => {
    if (!input) return;

    onSearch({
      selectedItems: [ input ],
      searchMode: searchType === SearchType.CheckAddress ? AppMode.Check : AppMode.Buy,
    });

    const isNonProjectSearch = input.type !== CompletionType.Project && input.type !== CompletionType.CommercialProject && searchType === SearchType.Buy;

    const shouldPageOpen = input.type === CompletionType.Developer || input.type === CompletionType.Agent || input.type === CompletionType.Office;

    if (!isWizardCompleted && isNonProjectSearch && handleSearch && !shouldPageOpen) {
      handleSearch(input);
      return;
    }

    const { route, params, options } = getResidentialNavigationParams(
      input,
      searchType === SearchType.CheckAddress ? AppMode.Check : AppMode.Buy
    );
    if (route === Route.Search) params.tracking_search_source = 'new_search';

    navigate(route, params, options);
  };

  const handleDefaultSearch = () => {
    const cityTerm = [ config.cityTerm ];
    if (searchType === SearchType.Buy) {
      navigate(Route.Search, {
        term: cityTerm,
        dealType: DealType.Buy,
      });
    }
    else {
      navigate(Route.CheckAddress, {
        term: cityTerm,
        dealType: undefined,
      });
    }
  };

  const handleSearchButton = () => {
    if (isEmptySearch) {
      return isMobile
        ? downshiftRef.current.openMenu()
        : isWizardCompleted ? handleDefaultSearch() : handleNullSearch();
    }
    if (suggestions[0]) {
      handleAutocompleteChange(suggestions[0]);
    }
    else {
      handleDefaultSearch();
    }
  };

  const handleEnterButton = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;

    if (!suggestions.length) {
      handleDefaultSearch();
    }
  };

  const handleTypeSelect = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setSearchType(e.currentTarget.value as SearchType);
    onSetSearchType(e.currentTarget.value as SearchType);
  };

  return (
    <SearchBlockRoot>
      <SearchTypeSelectWrapper>
        {isMobile ? null : (
          <SearchTypeSelectLabel>
            {t('homePage.autocomplete.typeSelect.label')}
          </SearchTypeSelectLabel>
        )}
        <input
          name="typeSelect"
          type="radio"
          id="typeSelectOption1"
          checked={SearchType.Buy === searchType}
          value={SearchType.Buy}
          onChange={handleTypeSelect}
        />
        <label htmlFor="typeSelectOption1" data-auto="search-address">
          {t('homePage.autocomplete.typeSelect.option1Label')}
        </label>
        <Separator>/</Separator>
        <input
          name="typeSelect"
          type="radio"
          id="typeSelectOption2"
          checked={SearchType.CheckAddress === searchType}
          value={SearchType.CheckAddress}
          onChange={handleTypeSelect}
        />
        <label htmlFor="typeSelectOption2" data-auto="check-address">
          {t('homePage.autocomplete.typeSelect.option2Label', { isMobile })}
        </label>
      </SearchTypeSelectWrapper>
      <InputWrapper onKeyDown={handleEnterButton} isMobile={isMobile} >
        <AddressAutocompleteInput
          showType
          downshiftRef={downshiftRef}
          disableHebrewLayoutConvert
          optionsCount={MAX_RESULTS}
          completionTypes={completionTypes}
          searchStr={searchStr}
          onSearchStrChange={setSearchStr}
          placeholder={t('homePage.autocomplete.placeholder', { isMobile })}
          inputIcon={<LocationIcon width={20} height={20}/>}
          onChange={handleAutocompleteChange}
          onSuggest={setSuggestions}
          source={AddressAutocompleteSource.HomePageTop}
          initialValue={null}
        />
      </InputWrapper>
      <SearchButton onClick={handleSearchButton}>
        <SearchIcon style={{ verticalAlign: 'middle', marginLeft: 8 }}/>
        {t('homePage.autocomplete.searchButton')}
      </SearchButton>
    </SearchBlockRoot>
  );
};

export default withEvents<SearchBlockProps>(sendEvent => ({
  onSearch(meta) {
    sendEvent('search_submit', 'search', {
      event: {
        source: 'homepage_top',
      },
      search: buildSearchPayload(meta),
    });
  },
  onSetSearchType(type: SearchType) {
    sendEvent('search_bar_tab_click', 'search', {
      event: {
        clicked_tab: type === SearchType.CheckAddress ? TabCategory.InfoPage : TabCategory.Residential,
      },
    });
  },
}))(SearchBlock);
