import styled from '@emotion/styled';

import { SmallText } from 'ds/components/typography';


export const IconWrapper = styled.div`
  ${({ theme }) => `
    height: 20px;
    margin: 0 ${theme.spacing(1)};
    transform: scaleX(${theme.isRTL ? '' : '-'}1);
  `}
`;

export const TypeIconWrapper = styled.div`
  height: 20px;
  display: flex;
  align-items: center;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const Breadcrumbs = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.neutrals.grey3};
    display: flex;
    height: 24px;
    align-items: center;
    margin-bottom: ${theme.spacing(7)};

    svg path {
      fill: currentColor;
    }
  `};
  ${({ theme }) => theme.media.showAt({ to: 2 }) `
    margin-bottom: ${theme.spacing(theme.isRTL ? 7 : 5)};
  `}
`;

export const CommuteRushHourWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

export const CloseSubStep = styled(SmallText)`
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledSubStepWrapper = styled.div`
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    padding-top: ${theme.isRTL ? theme.spacing(6) : 0};
  `};
`;
