import styled from '@emotion/styled';

export const Wrapper = styled.div`
  margin-bottom: 88px;
    > a {
    display: block;
    outline: none;
    text-decoration: none;
    text-align: center;
    margin-top: 56px;
  }
  ${({ theme }) => theme.media.showAt({ from: 2 })`
    margin-bottom: 112px;
  `}
`;
