import React, { useState }  from 'react';
import { connect } from 'react-redux';
import { useLocale } from 'locale';
import { homepageWizardSelector } from 'store/state/homepageWizard/selectors';
import PreferenceSelect from './preference-select';
import TransportCategories from './transport-categories';
import { HomepageWizardState } from 'store/state/homepageWizard';
import {
  submitWizard as submitWizardAction,
  setCommuteSubStep as setCommuteSubStepAction,
} from 'store/state/homepageWizard/actions';
import { CommuteSubStepWrapper } from 'components/commute-popup/components/CommuteSubStepWrapper';
import { CommuteType } from 'utils/entities';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { HRLine } from 'ds/components/Layout';
import { WizardCommuteSubStepCar, WizardCommuteSubStepWalk } from './connectedWizardSubSteps';
import {
  CategoryLabelWizard,
  CategoryLabelWizardText,
  WizardContentInner,
  WizardContentWrapper,
  CategoryWrapper,
} from '../../styled';

export type CommutePreferenceSelectPropsBase = Pick<HomepageWizardState, 'commutePreference' | 'isCommuteSubStep'>;

interface CommutePreferenceSelectProps extends CommutePreferenceSelectPropsBase {
  isAutoFocus?: boolean;
  submitWizard: () => void;
  setCommuteSubStep?: (s: boolean) => void;
}

const CommuteSubStep: React.FC<{ type: CommuteType }> = ({ type }) => {
  let content = null;

  switch (type) {
    case 'car':
      content = <WizardCommuteSubStepCar />;
      break;
    case 'walk':
      content = <WizardCommuteSubStepWalk />;
  }

  return content;
};

const SUBSTEP_TYPES: Set<CommuteType> = new Set([ 'car', 'walk' ]);

const isSubStepAllowed = (commuteType: CommuteType) => SUBSTEP_TYPES.has(commuteType);

export const Commute: React.FC<CommutePreferenceSelectProps> = ({
  commutePreference: { commuteType, text },
  isAutoFocus,
  isCommuteSubStep,
  setCommuteSubStep,
}) => {
  const isMobile = useScreenBreakpoint() < 3;
  const { t } = useLocale();
  const [ autoFocus, setAutoFocus ] = useState(true);
  const renderHRLine = isMobile ? <HRLine mBottom={4} /> : null;

  let commuteLabel = (
    <>
      {renderHRLine}
      <CategoryLabelWizard>
        {t('homePage.wizard.commute.going')}
      </CategoryLabelWizard>
    </>
  );

  const categoryLabel = !isMobile ? (
    <>
      {renderHRLine}
      <CategoryLabelWizardText caps>
        {t('homePage.wizard.commute.howMuchTime')}
      </CategoryLabelWizardText>
    </>
  ) : null;

  const bottomLabel = isMobile ? (
    <CategoryLabelWizardText caps>
      {t('homePage.wizard.commute.howMuchTime')}
    </CategoryLabelWizardText>
  ) : null;

  switch (commuteType) {
    case 'bus':
      commuteLabel = null;
      break;
    case 'train':
      commuteLabel = renderHRLine;
  }

  return (
    <WizardContentWrapper>
      <WizardContentInner>
        {isCommuteSubStep && isSubStepAllowed(commuteType) ? (
          <CommuteSubStepWrapper type={commuteType} commuteAddress={text} setCommuteSubStep={setCommuteSubStep}>
            <CommuteSubStep type={commuteType} />
          </CommuteSubStepWrapper>
        ) : (
          <>
            <CategoryWrapper big>
              {categoryLabel}
              <TransportCategories />
            </CategoryWrapper>
            {commuteType ? (
              <CategoryWrapper>
                {commuteLabel}
                {isAutoFocus ? (
                  <PreferenceSelect
                    autoFocus={autoFocus}
                    onBlur={() => setAutoFocus(false)}
                    isContentByCommuteTypeAllowed
                  />
                ) : (
                  <PreferenceSelect
                    isContentByCommuteTypeAllowed
                  />
                )}
              </CategoryWrapper>
            ) : bottomLabel}
          </>
          )}
      </WizardContentInner>
    </WizardContentWrapper>
  );
};

const mapDispatchToProps = {
  submitWizard: submitWizardAction,
  setCommuteSubStep: setCommuteSubStepAction,
};

export default connect(homepageWizardSelector, mapDispatchToProps)(Commute);
