import { AIDBanner, AIDBannerProps } from './aig-banner';
import withEvents from 'analytics/withEvents';

const AIDBannerWithEvents = withEvents<AIDBannerProps>((sendEvent) => ({
  bannerClick(link) {
    sendEvent('banner_click', 'banner', {
      event: {
        banner_id: 'aig_home_page',
        banner_url: link,
        banner_is_external: true,
      },
    });
  },
}))(AIDBanner);

export default AIDBannerWithEvents;
